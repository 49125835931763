import React, {Component} from "react";
import {getApi} from "../../utils/Api";

class SmsSjednani extends Component {

  static get StatusWaiting() {
    return 'waiting';
  }

  static get StatusCodeSent() {
    return 'sent';
  }

  static get StatusCodeDone() {
    return 'done';
  }

  constructor(props: Props) {

    super(props);

    this.state = {
      status: SmsSjednani.StatusWaiting,
      smsKod: null,
      error: null,
      telefon: null
    };

  }

  /**
   * Odesle Na telefonni cislo kod
   */
  getSmsCode = () => {
    let self = this;

    getApi().post('/notificator/verification', {action: 'getCode', token: this.props.token, cisloSmlouvy: this.props.cislo_smlouvy }, undefined, true).then(function (response: any) {
      if (response.status === 200 && response.data.status === 'ok') {
        self.setState({
          status: SmsSjednani.StatusCodeSent,
          telefon: response.data.telefon,
          error: null
        });
      } else {
        console.log('error', response.data.errorMessage);
        self.setState({error: response.data.errorMessage});
      }
    }).catch((error) => {
      self.setState({error: 'Došlo k neočekávané chybě při odesílání kódu. Zkuste to znovu.'});
      console.error(error);
    });
  }

  /**
   * Odesle Na telefonni cislo kod
   */
  verifySmsCode = () => {
    let self = this;

    getApi().post('/notificator/verification', {action: 'verifyCode', token: this.props.token, smsKod: this.state.smsKod, cisloSmlouvy: this.props.cislo_smlouvy }, undefined, true).then(function (response: any) {
      if (response.status === 200 && response.data.status === 'ok') {

        if (self.props.onSmsVerified) {
          self.props.onSmsVerified({
            telefon: response.data.telefon,
            smsKod: response.data.smsKod,
            datum: response.data.datum
          });
        }

        self.setState({
          status: SmsSjednani.StatusCodeDone,
          error: null
        });
      } else {
        self.setState({error: response.data.errorMessage});
      }
    }).catch((error) => {
      self.setState({error: 'Došlo k neočekávané chybě při ověřování kódu. Zkuste to znovu.'});
      console.error(error);
    });
  }

  render() {
    let self = this;

    if (this.isStatusWaiting()) {
      return (
        <div>
          {this.props.hideHeading ? null : <h2>Elektronický podpis</h2>}
          <div className="validation__sign-box" style={{padding: 0}}>
            <div>
              <span>
                <div>
                  <button onClick={this.getSmsCode} className="btn text-uppercase">Zaslat potvrzovací kód</button>
                </div>
              </span>
            </div>
          </div>
          {self.state.error && <div className='width--100--percent text-right padding--top--5 text-darken-red'>{self.state.error}</div>}
        </div>

      );
    } else if (this.isStatusSent()) {
      return (
        <div className="validation__form validation__form--right">
          <div className={"padding--bottom--10 text-darken-red text-right width--100--percent"}>
            Na vaše telefonní číslo {this.state.telefon} byla odeslána SMS s potvrzovacím kódem.<br/>
            Pro podepsání smlouvy opište kód z SMS a potvrďte.
          </div>

          <div className="validation__form__input">
            <input autoComplete={'one-time-code'} inputMode={'numeric'} onChange={(event) => {self.setState({smsKod: event.target.value}); }} key="2" type="text" placeholder="kód"/>
          </div>
          <div className="validation__form__btn">
            <input onClick={this.verifySmsCode} type="submit" className="btn text-uppercase" value="Podepsat smlouvu" />
          </div>

          {self.state.error && <div className='width--100--percent text-right padding--top--5 text-darken-red'>{self.state.error}</div>}

          <div className='width--100--percent text-right text-gray padding--top--5'>
            <button onClick={this.getSmsCode} className='gray-link pointer btn--text-link'>zaslat znovu</button>
          </div>
        </div>
      );
    } else if (this.isStatusDone()) {
      return (
        <div className={"success"}>Smlouva byla úspěšně ověřena a podepsána.</div>
      );
    }

    return null;
  }

  isStatusWaiting() {
    return this.state.status === SmsSjednani.StatusWaiting;
  }

  isStatusSent() {
    return this.state.status === SmsSjednani.StatusCodeSent;
  }

  isStatusDone() {
    return this.state.status === SmsSjednani.StatusCodeDone;
  }

}

export default SmsSjednani;
