import React, {Component} from "react";

export default class FormErrors extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errorList: props.errors ? props.errors : []
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({
			errorList: props.errors ? props.errors : []
		});
	}

	render() {
		return (
			<div className='formErrors'>
				{this.state.errorList.length > 0 && this.state.errorList.map((fieldError, i) => {
					if (fieldError.length > 0) {
						return (
							<p key={i}>{fieldError}</p>
						)
					} else {
						return '';
					}
				})}
			</div>
		)
	};
}
