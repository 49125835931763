import React, {Component} from "react";
import {Chart} from 'react-google-charts';

const chartEvents = [
	{
		eventName: "select",
		callback({chartWrapper}) {
			if (chartWrapper !== undefined) {
				chartWrapper.getChart().setSelection([]);
			}
		}
	}
];

class Graf extends Component {

	constructor(props) {
		super(props);
		this.state = {
			title: props.title,
			data: props.data,
			id: props.id,
			colors: props.colors,
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
	};

	getData = () => {
		return this.state.data;
	};

	getTitle = () => {
		return this.state.title;
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			title: nextProps.title,
			data: nextProps.data,
			id: nextProps.id,
			colors: nextProps.colors
		});
	}

	render = () => {
		return (
			<div className={'graf'}>
				<Chart
					loader=" "
					chartType="PieChart"
					data={this.getData()}
					options={{
						title: this.getTitle(),
						pieHole: 0.8,
						legend: {position: 'none'},
						chartArea: {'width': '90%', 'height': '90%'},
						colors: this.state.colors,
						pieSliceText: 'none',
						backgroundColor: 'transparent',
					}}
					graph_id={this.state.id}
					width="100%"
					height="160px"
					legend_toggle
					chartEvents={chartEvents}
				/>
			</div>
		);
	};
}

export default Graf;
