import React, {Component} from "react";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";

export default class ProdejMajetkuPrehled extends Component {

	constructor(props) {
		super(props);
		this._myplannClient = getContainer().getMyplannClient();
		this._uzivatel = getContainer().getUzivatel();
		this._dateHelper = getContainer().getDateHelper();
		this.state = {
			item: props.item, // majetek
			majetekProdejStavy: props.item.prodejStavy,
			showHistory: []
		};
	}

	/**
	 * @return MyplannClient
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	/**
	 * @return DateHelper
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	componentDidMount() {
	}

	toggleHistory = (e, index) => {
		let row = this.state.majetekProdejStavy[index];
		let history = this.state.showHistory;

		if (history.hasOwnProperty(index)) {
			delete history[index];
		} else {
			history[index] = row;
		}

		this.setState({
			showHistory: history
		});
	};

	zrusitProdej = (e, index) => {
		confirmDialog("Zrušení prodeje", "Opravdu chcete zrušit prodej u tohoto majetku?", "Zrušit prodej").then(
			result => {
				let row = this.state.majetekProdejStavy[index];
				this.getMyplannClient().majetekProdejZrusit(row.id_prodeje).then((response) => {
					if (response && response.data.data.success) {
						this.setState({
							majetekProdejStavy: []
						});
					}
				});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	render = () => {
		if (this.state.majetekProdejStavy === undefined || this.state.majetekProdejStavy.length <= 0) {
			return '';
		}

		return (
			<div>
				{this.state.majetekProdejStavy.map((item, index) => {
					return (
						<div key={index}>
							<div className={"portfolio__progress-row"} key={index}>
								<div className={"portfolio__progress-row__name"}>
									<i>Prodej nemovitosti: </i>
									{item.realitka !== "" ? item.realitka : "[Neznámá RK]"}
								</div>
								<div className={"portfolio__progress-row__graph"}>
									<div title={'Nabídka odeslána'}
										 className={"portfolio__progress-row__graph__dot " + (item.stav >= 100 ? 'done' : 'undone')}>o
									</div>
									<div title={'Nabídka přijata'}
										 className={"portfolio__progress-row__graph__dot " + (item.stav >= 200 ? 'done' : 'undone')}>o
									</div>
									<div title={'Klient kontaktován'}
										 className={"portfolio__progress-row__graph__dot " + (item.stav >= 300 ? 'done' : 'undone')}>o
									</div>
									<div title={'Dohodnuta prodejni cena'}
										 className={"portfolio__progress-row__graph__dot " + (item.stav >= 400 ? 'done' : 'undone')}>o
									</div>
									<div title={'Nemovitost prodána'}
										 className={"portfolio__progress-row__graph__dot " + (item.stav >= 500 ? 'done' : 'undone')}>o
									</div>
								</div>
								<div className={"portfolio__progress-row__show-more"}>
									<span className={'a pointer'} onClick={(e) => {
										this.toggleHistory(e, index);
									}}>
										{this.state.showHistory.hasOwnProperty(index) ?
											<i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>}
										&nbsp; Více informací
									</span>
								</div>
								<div className={"portfolio__progress-row__cancel"}>
									{!this._uzivatel.readAccessOnly() ?
										<span className="a delete pointer delete__cross--small d-inline-block hide-text"
										   onClick={(e) => {
											   this.zrusitProdej(e, index);
										   }}>Zrušit prodej</span>
										: ""}
								</div>
							</div>
							<div
								className={(this.state.showHistory.hasOwnProperty(index) ? "portfolio__logs-container" : "")}>
								{this.state.showHistory.hasOwnProperty(index) && item.logy_prodeje.map((log_prodeje, index) => {
									return (
										<div className={"portfolio__logs-row"} key={index}>
											<div>
												{log_prodeje.novy_stav_name}
											</div>
											<div>
												{this.getDateHelper().reformatDate(log_prodeje.datum, false, true, 'D. M. YYYY - HH:mm')}
											</div>
											<div>
												{log_prodeje.text}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	};
}
