// Default components encapsulates actual
// application content seen by logged user

import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {loggedIn, login, logout} from "../utils/Api";
import Portfolio from "./Portfolio/Portfolio";
import Incidents from "./Incidents/Incidents";
import Home from "./Home/Home";
import Header from './Header';
import Loading from "./utils/Loading";
import ErrorMessage from './utils/ErrorMessage';
import Profil from "./Uzivatel/Profil";
import {getContainer} from "../utils/generic";
import NotFound from "./NotFound/NotFound";
import EmptyPage from './EmptyPage';

class Default extends Component {
	constructor(props) {
		super(props);

		this._myplannClient = getContainer().getMyplannClient();

		this.state = {
			...props,
			user: null
		};
	}

	componentDidMount = () => {
		// if firstLogin is true, we don't need get userInfo because page will be redirected
		if (getContainer().getUzivatel().firstLogin() !== true) {
			this._myplannClient.userInfo().then(
				(data) => {
				if (data) {
					this.setState({
						user: data,
					});
				}
			}, () => {
				this.props.history.push('/login');
			});
		}
	};

	userUpdate = (newData) => {
		let user = this.state.user;
		if (user) {
			user = newData;

			let updatedData = this._myplannClient.updateUserInfo(user);

			this.setState({
				user: updatedData
			});
		}
	};

	render = () => {
		// if user is not logged in, redirect him to /login
		if (!loggedIn()) {
      this._myplannClient.getNastaveni();
      login(this.state.username, this.state.password).then((response) => {
        if (response && response.data) {
          this.setState({redirectToReferrer: true});
        } else {
          window.location.href = "/login";
        }
        return response;
      }).catch((e) => {

      });
			return '';
			// problem s cookie uz mozna neni (jak je to na stejnem serveru)
			// ozkousime to takhle, resi to to blikani
			// return <Redirect to={"/login"} />;
		}

		let uzivatel = getContainer().getUzivatel();
		if (uzivatel.hasExpired()) {
			logout();
			return '';
		}

		if (uzivatel.firstLogin()) {
			return <Redirect to={"/prvni-prihlaseni"}/>;
		}

		if (uzivatel.changePassword()) {
			return <Redirect to={"/change-password"}/>;
		}

		if (this.state.user) {
			let title = document.querySelector('title');
			if (title && title.hspName === undefined) {
				title.hspName = this.state.user.hspName;
				title.text = title.text + ' | ' + title.hspName;
			}
		}

		if (this.state.user === null) {
			return (
				<EmptyPage />
			);
		}

		// otherwise show the contents
		return (
			<div>
				<ErrorMessage/>
				<Loading show={false}/>
				<Header user={this.state.user}/>

				{/* this basically is an if-else block: assigned component is rendered for matching route*/}
				<Switch>
					{/* Route for component Home */}
					<Route exact path={`${this.state.match.url}`} component={Home}/>

					{/* Route for component Profil */}
					<Route path={`${this.state.match.url}profil`} render={props => (
						<Profil
							{...props}
							user={this.state.user}
							userUpdate={this.userUpdate.bind(this)}
						/>
					)}/>

					{/* Route for component Portfolio */}
					<Route path={`${this.state.match.url}portfolio`} component={Portfolio}/>

					{/* Route for component Incidents */}
					<Route path={`${this.state.match.url}udalosti`} component={Incidents}/>

					{/* Route for component CestovniPojisteni */}
					{/*<Route path={`${this.state.match.url}cestovni-pojisteni`} component={CestovniPojisteni}/>*/}

					{/* Route for 404 */}
					<Route path="*" component={NotFound}/>
				</Switch>
			</div>
		);
	}
};

// default export = what you get when you import whole module
export default Default;
