import {Component} from "react";
import * as PubSub from "pubsub-js";
import DropzoneFile from "../DropzoneFile";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";

class BaseSmlouvyForm extends Component {

	constructor(props) {
		super(props);
		this.files = [];
		this._client = getContainer().getMyplannClient();
		this._dateHelper = getContainer().getDateHelper();
		this._uzivatel = getContainer().getUzivatel();
		this._datepickerErrs = {};
	}

	/**
	 * @returns {MyplannClient}
	 */
	getMyplannClient = () => {
		return this._client;
	};

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	// submits the form data
	// supposes that onSubmit has been passed as prop
	submit = (e) => {
		e.preventDefault();
		let data = this.state;
		// log(data);
		this.props.onSubmit(data, this.files);
	};

	// this is two-way binding - see comments at input
	// and read https://reactjs.org/docs/two-way-binding-helpers.html
	// (link shows that this can also be done in a different way)
	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
            selectedProducentSuggestion: e.selectedProducentSuggestion
		});
		this.props.onFormChange();
	};

	onDateChange = (name, value) => {
		if (value && value.format) {
			value = value.format(this._dateHelper.getActiveFormat());
		}
		this.setState({
			[name]: value,
            saveBtn: {
                disabled: false,
                text: this.state.saveBtn.text
            }
		});
		this.props.onFormChange();
	};

	onChangeOnlyNumbers = e => {
		const re = /^[0-9\b\s]+$/;
		let value = e.target.value.replace(/\s+/g, "");
		if (value === '' || re.test(value)) {
			this.setState({[e.target.name]: value});
		}
		this.props.onFormChange();
	};

	showFormatedNumber = (number) => {
		if (number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		} else {
			return "";
		}
	};

	smazat = () => {
		let confirmOptions = {
			additionalConfirmMessage: 'Položka bude nenávratně smazána.<br/>Chcete-li položku zachovat, zavřete křížkem nebo vyberte <em>zrušit</em>.'
		};
		confirmDialog("Smazání smlouvy", "Opravdu chcete smazat smlouvu?", "smazat smlouvu", confirmOptions).then(
			result => {
				// `proceed` callback
				PubSub.publish("smazatSmlouvu", {id: this.state.id});
				PubSub.publish("reloadCharts");
			},
			result => {
				// `cancel` callback
			}
		);
	};

	onDrop = (files: DropzoneFile[]) => {
		this.files = files;
		this.props.onFormChange();
	};

    onDatepickerError = (name, value) => {
        this._datepickerErrs[name] = true;
		this.setState({
            [name]: value, //date field refresh with invalid value.
			// This solves the problem with value overriding to last valid date in datepicker.
			// User is required to fix date input value to save form. He must see the invalid date to correct his mistake.
			saveBtn: {
				disabled: true,
				text: this.state.saveBtn.text
			}
		});
    };

    onDatepickerErrorRemoved = (name, value) => {
		let disabled = false;
        this._datepickerErrs[name] = false;
        if (this._datepickerErrs) {
            for (let key in this._datepickerErrs) {
                if (this._datepickerErrs.hasOwnProperty(key)) {
                    if (this._datepickerErrs[key] === true) {
                    	disabled = true;
                    }
                }
            }
        }

		this.setState({
            [name]: value,
			saveBtn: {
				disabled: disabled,
				text: this.state.saveBtn.text
			}
		});
    };
}

export default BaseSmlouvyForm;
