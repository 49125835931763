import React, {Component} from "react";
import 'moment/locale/cs';
import Dispozice from "../../utils/Dispozice";
import Input from "../../utils/Input";
import ReactDOM from "react-dom";
import FormErrors from "../../utils/FormErrors";
import {getDefaultData} from "./formHelpers";
import CheckboxGroup from "../../utils/CheckboxGroup";

export default class Chata extends Component {
	constructor(props) {
		super(props);
		this.state = this.emptyData();
		this.disabled = false;
		if (props.asset) {
			this.disabled = (props.asset.data && props.asset.data.hasOwnProperty("dokoncen") ? props.asset.data.dokoncen || this.getUzivatel().readAccessOnly() : this.getUzivatel().readAccessOnly());

			let dates = {};
			this.state = Object.assign(dates, this.emptyData(), {...props.asset.data});
		}
		this.validateErrors = [];
	}

	emptyData = () => {
		return getDefaultData("chata", true, true, false, true, {
			...this.props.formConfiguration.propertyParameters,
			...this.props.formConfiguration.propertyStatus,
			...this.props.formConfiguration.propertyMaterials,
			...this.props.formConfiguration.propertyOwnership,
			...this.props.formConfiguration.propertyRegion,
		});
	};

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		return this.props.dateHelper;
	};

	/**
	 * @returns {Uzivatel}
	 */
	getUzivatel = () => {
		return this.props.uzivatel;
	};

	submit = e => {
		e.preventDefault();
		let validateErrors = [];

		if (this.state.minCena !== "" && this.state.maxCena !== "") {
			if (parseInt(this.state.minCena, 10) > parseInt(this.state.maxCena, 10)) {
				validateErrors.push("Minimální cena chaty musí být nižší než maximální cena")
			}
		}

		if (this.state.plochaMin !== "" && this.state.plochaMax !== "") {
			if (parseInt(this.state.plochaMin, 10) > parseInt(this.state.plochaMax, 10)) {
				validateErrors.push("Minimální požadovaná plocha musí být nižší než maximální plocha")
			}
		}

		if (validateErrors.length === 0) {
			let data = this.state;
			this.props.onSubmit(data);
		} else {
			const element = ReactDOM.findDOMNode(this);
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth'
			});
		}

		this.validateErrors = validateErrors;
		this.setState({});
	};

	onChange = e => {
		this.props.onChange(e, this);
	};

	onFieldChange = (name, value) => {
		this.props.onFieldChange(name, value, this);
	};

	onChangeOnlyNumbers = e => {
		const re = /^[0-9\b\s]+$/;
		let value = e.target.value.replace(/\s+/g, "");
		if (value === '' || re.test(value)) {
			this.props.onFieldChange(e.target.name, value, this);
		}
	};

	showFormatedNumber = (number) => {
		if (number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		} else {
			return "";
		}
	};

	render() {
		return (
			<div>
				<form className={'form'} onSubmit={this.submit}>
					<h1>Chata</h1>
          <div className={'form-description'}>
            <p>Vyplňte poptávkový formulář, odešlete a následně Vás bude kontaktovat realitní specialista.</p>
          </div>
					<FormErrors errors={this.validationErrors}/>
					<input type="hidden" name="druh" value={this.state.druh}/>
					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<tbody>
						<tr className={"modal-form-table__select-input-row"}>
							<td>
								Dispozice min.
							</td>
							<td>
								<Dispozice
									onChange={(e, name) => {
										this.props.onDispoziceMinChange(e, name, this);
									}}
									disabled={this.disabled}
									name={'minDispozice'}
									value={this.state.minDispozice}
									options={this.props.formConfiguration.propertyDisposition}
								/>
							</td>
						</tr>
						<tr className={"modal-form-table__select-input-row"}>
							<td>
								Dispozice max.
							</td>
							<td>
								<Dispozice
									onChange={(e, name) => {
										this.props.onDispoziceMaxChange(e, name, this);
									}}
									disabled={this.disabled}
									name={'maxDispozice'}
									value={this.state.maxDispozice}
									options={this.props.formConfiguration.propertyDisposition}
								/>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td__title"}>
								<div>Stav:</div>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td checkboxes-td--with-title"}>
								<CheckboxGroup
									onChange={this.onChange}
									options={this.props.formConfiguration.propertyStatus}
									disabled={this.disabled}
									defaults={this.state}
								/>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td__title"}>Kraj:</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td checkboxes-td--with-title checkboxes-td__last"}>
								<div>
									<CheckboxGroup
										onChange={this.onChange}
										options={this.props.formConfiguration.propertyRegion}
										disabled={this.disabled}
										defaults={this.state}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								Lokalita
							</td>
							<td>
								<Input onChange={this.onChange} type="text" name="lokalita"
									   value={this.state.lokalita} disabled={this.disabled}/>

							</td>
						</tr>
						<tr>
							<td>
								Min. cena
							</td>
							<td>
								<Input onChange={this.onChangeOnlyNumbers} type="text" name="minCena" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.minCena)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> Kč</span>
							</td>
						</tr>
						<tr>
							<td>
								Max. cena
							</td>
							<td>
								<Input onChange={this.onChangeOnlyNumbers} type="text" name="maxCena" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.maxCena)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> Kč</span>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td__title"}>
								<div>Vybavení:</div>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td checkboxes-td--with-title"}>
								<CheckboxGroup
									onChange={this.onChange}
									options={this.props.formConfiguration.propertyParameters}
									disabled={this.disabled}
									defaults={this.state}
								/>
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td__title"}>
								Materiál:
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td checkboxes-td--with-title"}>
								<CheckboxGroup
									onChange={this.onChange}
									options={this.props.formConfiguration.propertyMaterials}
									disabled={this.disabled}
									defaults={this.state}
								/>

							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td__title"}>
								Vlastnictví:
							</td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td checkboxes-td--with-title checkboxes-td__last"}>
								<div>
									<CheckboxGroup
										onChange={this.onChange}
										options={this.props.formConfiguration.propertyOwnership}
										disabled={this.disabled}
										defaults={this.state}
									/>
								</div>

							</td>
						</tr>
						<tr>
							<td>
								Užitná plocha min.
							</td>
							<td><Input onChange={this.onChangeOnlyNumbers} type="text" name="plochaMin" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.plochaMin)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> m<sup>2</sup></span>
							</td>
						</tr>
						<tr>
							<td>
								Užitná plocha max.
							</td>
							<td><Input onChange={this.onChangeOnlyNumbers} type="text" name="plochaMax" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.plochaMax)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> m<sup>2</sup></span>
							</td>
						</tr>
						<tr>
							<td>Doplňující popis chaty</td>
							<td><textarea onChange={this.onChange} name="popis"
										  value={this.state.popis} disabled={this.disabled}/></td>
						</tr>

						{this.getUzivatel().readAccessOnly() || this.disabled ? '' :
							<tr className={'conrol-row'}>
								<td className={'td-delete'}>
									{this.state.id_nakupu !== undefined ?
										<span onClick={() => this.props.zrusitNakup(this.state.id_nakupu)}
										   className={'delete pointer'}>
											smazat poptávku
										</span>
										: ''
									}
								</td>
								<td>
									<input type="submit" className="btn" value="uložit"/>
								</td>
							</tr>
						}

						</tbody>
					</table>
				</form>
			</div>
		);
	}
}
