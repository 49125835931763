import React from "react";
import moment from 'moment';
import 'moment/locale/cs';
import BaseMajetekForm from "./BaseMajetekForm";
import SmartformAdresa from "./SmartformAdresa";
import ForceUpdateScrollbars from "../../ForceUpdateScrollbars/ForceUpdateScrollbars";
import FilesList from "./filesUpload/FilesList";
import FilesDropzone from "./filesUpload/FilesDropzone";
import DatePickerWithListener from "../../utils/DatePickerWithListener";

const emptyData = {
	ulice: '',
	obec: '',
	cast_obce: '',
	psc: '',
	pocet_podlazi: "",
	rozmer: "",
	hodnota: "",
	hodnota_vnitrniho_vybaveni: "",
	velikost_pozemku: "",
	hodnota_pozemku: "",
	datum_koupeno: "",
	druh: "chata",
	uzitna_plocha: '',
	podlahova_plocha: '',
	dispozice_vlastni: '',
	material_stavby_vlastni: '',
	documents: [],
	files: [],
};

class ChataForm extends BaseMajetekForm {
	constructor(props) {
		super(props);
		this.files = [];
		this.state = emptyData;
		this.disabled = false;
		if (props.asset) {
			this.disabled = props.asset.disabled ? props.asset.disabled || this._uzivatel.readAccessOnly() : this._uzivatel.readAccessOnly();
			this.state = Object.assign({}, {...props.asset.data}, {documents: props.asset.documents}, {files: []});
		}

		if (!this.state.dispozice) {
			this.state.dispozice = "vlastni";
		}

		if (!this.state.material_stavby) {
			this.state.material_stavby = "vlastni";
		}

		this.state.saveBtn = {
			disabled: false,
			text: "uložit"
		};
	}

	emptyData = () => {
		return emptyData;
	};

	onDrop = (files) => {
		let saveBtn = this.state.saveBtn;

		if (files.length > 0) {
			saveBtn.text = "nahrát soubory a uložit";
		} else {
			saveBtn.text = "uložit";
		}

		this.setState({
			saveBtn,
			files
		});

		this.props.onFormChange();
	};

	onDocumentDelete = (document) => {
		if (this.state.documents) {
			let documents = this.state.documents.filter(function (item) {
				return item.id !== document;
			});

			this.setState({
				documents: documents,
			});
		}
	};

	render() {
		return (
			<div>
				<form className={'form'} onSubmit={this.submit.bind(this)}>
					<h1>{this.state.nazev && this.state.nazev.trim().length > 0 ? this.state.nazev : 'Chata'}</h1>
					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<SmartformAdresa
							onSuggestionMenuOpened={this.onSuggestionMenuOpened.bind(this)}
							onSuggestionMenuClosed={this.onSuggestionMenuClosed.bind(this)}
							onChange={this.onChange}
							STREET_AND_NUMBER={this.state.ulice}
							CITY={this.state.obec}
							PART_OF_TOWN={this.state.cast_obce}
							ZIP={this.state.psc}
							disabled={this.disabled}
						/>
						<tbody>
						<tr>
							<td>Užitná plocha</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="uzitna_plocha"
									   className="nfthousands" autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.uzitna_plocha)} maxLength={11}
									   disabled={this.disabled}/><span> m<sup>2</sup></span></td>
						</tr>
						<tr>
							<td>Podlahová plocha</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="podlahova_plocha"
									   className="nfthousands" autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.podlahova_plocha)} maxLength={11}
									   disabled={this.disabled}/><span> m<sup>2</sup></span></td>
						</tr>
						<tr>
							<td>Dispozice</td>
							<td>
								<select name="dispozice" disabled={this.disabled} onChange={this.onChange}
										value={(this.state.dispozice ? this.state.dispozice : "vlastni")}>
									<option value="1+kk">1+kk</option>
									<option value="1+1">1+1</option>
									<option value="2+kk">2+kk</option>
									<option value="2+1">2+1</option>
									<option value="3+kk">3+kk</option>
									<option value="3+1">3+1</option>
									<option value="4+kk">4+kk</option>
									<option value="4+1">4+1</option>
									<option value="5+kk">5+kk</option>
									<option value="5+1">5+1</option>
									<option value="6 a více">6 a více</option>
									<option value="vlastni">Jiná</option>
								</select>
							</td>
						</tr>
						{this.state.dispozice === "vlastni" || !this.state.dispozice ?
							<tr>
								<td></td>
								<td>
									<input onChange={this.onChange} disabled={this.disabled} type="text"
										   name="dispozice_vlastni"
										   value={this.state.dispozice_vlastni}/>
								</td>
							</tr>
							: null}
						<tr>
							<td>Materiál stavby</td>
							<td>
								<select name="material_stavby" onChange={this.onChange} disabled={this.disabled}
										value={(this.state.material_stavby ? this.state.material_stavby : "vlastni")}>
									<option value="Panel">Panel</option>
									<option value="Cihla">Cihla</option>
									<option value="Dřevostavba">Dřevostavba</option>
									<option value="Železobeton">Železobeton</option>
									<option value="vlastni">Jiný</option>
								</select>
							</td>
						</tr>
						{this.state.material_stavby === "vlastni" || !this.state.material_stavby ?
							<tr>
								<td></td>
								<td>
									<input onChange={this.onChange} type="text" name="material_stavby_vlastni"
										   disabled={this.disabled} value={this.state.material_stavby_vlastni}/>
								</td>
							</tr>
							: null}
						<tr>
							<td>Počet podlaží</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="pocet_podlazi"
									   className="nfthousands" autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.pocet_podlazi)} maxLength={3}
									   disabled={this.disabled}/></td>
						</tr>
						<tr>
							<td colSpan={2} className={"checkboxes-td"}>
								<input onChange={this.onChangeCheckbox} type="checkbox" name={"terasa"} id="terasa"
									   className="text-checkbox" defaultChecked={this.state.terasa}
									   disabled={this.disabled}/>
								<label htmlFor="terasa">Terasa</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"lodzie"} id="lodzie"
									   className="text-checkbox" defaultChecked={this.state.lodzie}
									   disabled={this.disabled}/>
								<label htmlFor="lodzie">Lodžie</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"balkon"} id="balkon"
									   className="text-checkbox" defaultChecked={this.state.balkon}
									   disabled={this.disabled}/>
								<label htmlFor="balkon">Balkón</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"garaz"} id="garaz"
									   className="text-checkbox" defaultChecked={this.state.garaz}
									   disabled={this.disabled}/>
								<label htmlFor="garaz">Garáž</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"sklep"} id="sklep"
									   className="text-checkbox" defaultChecked={this.state.sklep}
									   disabled={this.disabled}/>
								<label htmlFor="sklep">Sklep</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"vytah"} id="vytah"
									   className="text-checkbox" defaultChecked={this.state.vytah}
									   disabled={this.disabled}/>
								<label htmlFor="vytah">Výtah</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"bezbarierovy"}
									   id="bezbarierovy" className="text-checkbox"
									   defaultChecked={this.state.bezbarierovy} disabled={this.disabled}/>
								<label htmlFor="bezbarierovy">Bezbariérový</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"drevostavba"}
									   id="drevostavba"
									   className="text-checkbox" defaultChecked={this.state.drevostavba}
									   disabled={this.disabled}/>
								<label htmlFor="drevostavba">Dřevostavba</label>

								<input onChange={this.onChangeCheckbox} type="checkbox" name={"bazen"} id="bazen"
									   className="text-checkbox" defaultChecked={this.state.bazen}
									   disabled={this.disabled}/>
								<label htmlFor="bazen">Bazén</label>
							</td>
						</tr>
						<tr>
							<td>Odhadní cena nemovitosti</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="hodnota"
									   className="nfthousands dontclear" disabled={this.disabled} autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.hodnota)}
									   maxLength={11}/><span> Kč</span></td>
						</tr>
						<tr>
							<td>Vnitřní hodnota nemovitosti</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="hodnota_vnitrniho_vybaveni"
									   className="nfthousands dontclear" disabled={this.disabled} autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.hodnota_vnitrniho_vybaveni)}
									   maxLength={11}/><span> Kč</span></td>
						</tr>
						<tr>
							<td>Datum pořízení nemovitosti</td>
							<td>
								<DatePickerWithListener
									name={'datum_koupeno'}
									selected={this.state.datum_koupeno ? moment(this.state.datum_koupeno, this.getDateHelper().getActiveFormat(), true) : undefined}
									onChange={date => this.onFieldChange('datum_koupeno', date)}
									dateFormat={this.getDateHelper().getActiveFormat()}
									disabled={this.disabled}
                                    onError={this.onDatepickerError}
                                    onErrorRemoved={this.onDatepickerErrorRemoved}
								/>
							</td>
						</tr>
						</tbody>
					</table>

					<div className={'padding--bottom--10'}>
						<h2 className={'padding--bottom--10 ' + (this._uzivatel.readAccessOnly() ? 'padding--top--10 ' : '') + 'font-size--14'}>Fotky</h2>
						<div className={'contract-documents__container'}>
							<ForceUpdateScrollbars
								className="contract-documents__scrollbox"
								autoHeight
								autoHeightMin={0}
								autoHeightMax={234}
								forceupdaterendertimer={1000}
							>
								<div
									className={'contract-documents__scrollbox__container contract-documents__scrollbox__container--boxes'}>
									{this.disabled ? "" : <FilesDropzone onDrop={this.onDrop.bind(this)}/>}
									<FilesList documents={this.state.documents}
												onDelete={this.onDocumentDelete.bind(this)} disabled={this.disabled}/>
								</div>
							</ForceUpdateScrollbars>
						</div>
					</div>

					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<tbody>
						<tr>
							<td>Doplňující popis chaty</td>
							<td><textarea onChange={this.onChange} name="popis" disabled={this.disabled}
										  value={this.state.popis}/></td>
						</tr>
						{this._uzivatel.readAccessOnly() || this.disabled ? null :
							<tr className={'conrol-row'}>
								<td className={'td-delete'}>
									{this.state.id !== undefined && this.state.disabledDelete !== true ?
										<span onClick={this.smazat} className={'delete pointer'}>smazat majetek</span> : ''}
								</td>
								<td>
									<input type="submit" name={"add"} className="btn" value={this.state.saveBtn.text}
										   disabled={this.state.saveBtn.disabled}/>
								</td>
							</tr>
						}

						</tbody>
					</table>
				</form>
			</div>
		);
	}
}

export default ChataForm;
