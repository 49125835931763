import React, {Component} from 'react';
import {getUserObject, loggedIn, login} from '../../utils/Api';
import {Redirect} from 'react-router-dom';
import Adviser from '../Adviser/Adviser';
import Header from '../Header';
import Loading from '../utils/Loading';
import {getContainer} from '../../utils/generic';
import ForceUpdateScrollbars from '../ForceUpdateScrollbars/ForceUpdateScrollbars';

class PrvniPrihlaseni extends Component {

  constructor(props) {
    super(props);
    /** @var Uzivatel */
    this._uzivatel = getContainer().getUzivatel();
    /** @var MyplannClient */
    this._myplannClient = getContainer().getMyplannClient();

    this.state = {
      telefon_original: '',
      telefon_predvolba: '',
      telefon_cislo: '',
      code: '',
      error: false,
      message: null,
      codeSent: false,
      codeSentTelephone: '',
      codeSentInPast: false,
      name: '',
      rcic: '',
      gold: false,
      documentStatus: null,
      documentType: 'op',
      documentNumber: '',
      documentError: null,
      gold_allowed: false,
      deti_show: false,
      deti: []
    };
  }

	componentDidMount = () => {
	  this._myplannClient.userInfo().then((data) => {
	    if (data) {

	      var deti_show = false;
	      var deti = data.deti;
        if(data.deti && data.deti.length > 0){
          //ok
          deti_show = true;
        }else{
          deti = [
            {
              jmeno: '',
              rc: ''
            }
          ];
        }


	      this.setState({
	        telefon_original: data.phoneNumber,
	        telefon_predvolba: this.parsePhone(data.phoneNumber, false),
	        telefon_cislo: this.parsePhone(data.phoneNumber, true),
	        name: data.name,
	        rcic: data.rcic,
	        gold_allowed: data.zlaty_souhlas,
	        documentType: data.typ_dokladu.toLowerCase(),
          deti: deti,
          deti_show: deti_show
	      });
	    }
	  });
	};

	parsePhone = (phone, number) => {
	  if (phone) {
	    let rule = /^\+420[0-9]*$/;
	    if (rule.test(phone)) {
	      if (number) {
	        return phone.replace('+420', '');
	      } else {
	        return '420';
	      }
	    } else {
	      if (number) {
	        return phone.replace('+', '');
	      } else {
	        return '';
	      }
	    }
	  } else {
	    if (number) {
	      return '';
	    } else {
	      return '420';
	    }
	  }
	};

	souhlasUdelen = () => {
	  let code = this.state.code;
	  code = code.replace(/\s/g, '');

	  if (code.length > 0) {
	    this._myplannClient.prvniPrihlaseni_souhlasUdelen(code, '+' + this.state.telefon_predvolba + this.state.telefon_cislo, this.state.telefon_original, this.state.gold, this.state.deti_show ? this.state.deti : []).then((response) => {
	      // alert('Všechno proběhlo v pořádku. V dalším kroku si nastavíte heslo.')
	      login().then(() => {
	        window.location.href = '/';
	      });
	    }).catch((data) => {
	      if (data.response) {
	        if (data.response.data) {
	          if (data.response.data.exception) {
	            this.setState({
	              code: '',
	              hasError: true,
	              message: this.processErrorMessage(data.response.data.exception.message)
	            });
	            this.render();
	            return;
	          }
	        }
	      }
	      if (data.response) {
	        login().then(() => {
	          window.location.href = '/';
	        });
	        return;
	      }
	      this.setState({
	        codeSent: false,
	        code: '',
	        hasError: true,
	        message: 'Došlo k chybě během zpracovávání požadavku.'
	      });
	      this.render();
	    });
	  } else {
	    this.setState({
	      hasError: true,
	      message: 'Zadejte, prosím, SMS kód'
	    });
	  }
	};

	odeslatZnovu = () => {
	  this._myplannClient.prvniPrihlaseni_odeslatZnovuSms('+' + this.state.telefon_predvolba + this.state.telefon_cislo, this.state.telefon_original, this.state.gold).then((response) => {
	    if (response) {
	      this.setState({
	        codeSent: true,
	        hasError: false,
	        codeSentTelephone: '+' + this.state.telefon_predvolba + this.state.telefon_cislo,
	      });
	    }
	  }).catch((data) => {
	    if (data.response) {
	      if (data.response.data) {
	        if (data.response.data.exception) {
	          this.setState({
	            hasError: true,
	            message: this.processErrorMessage(data.response.data.exception.message)
	          });
	        }
	      }
	    }

	    throw data;
	  });
	};

	processErrorMessage = (message) => {
	  if (message === 'phone number is invalid') {
	    return 'Zadané telefonní číslo není platné';
	  } else if (message === 'invalid sms code') {
	    return 'Zadaný SMS kód není platný';
	  } else if (message === 'can not send sms') {
	    return 'SMS nelze odeslat, jelikož od odeslání předchozí SMS neuběhlo alespoň půl minuty.';
	  } else if (message === 'can not verify sms token') {
	    return 'Potvrzení pomocí SMS nyní nelze dokončit. Obraťte se prosím na svého poradce.';
	  } else if (message === 'bad number') {
	    return 'Zadané telefonní číslo se neshoduje s tím, které poradce uvedl při Vaší identifikaci. Kontaktujte prosím svého poradce.';
	  } else {
	    return 'Při zpracování vašeho podpisu došlo k chybě, zkuste prosím stránku obnovit a podpis opakovat.';
	  }
	};

	onChange = (e) => {
	  var value = e.target.value;
	  if(e.target.name === 'telefon_cislo' || e.target.name === 'telefon_predvolba') {
	    value = value.replace(new RegExp('[^0-9]', 'g'), '');
	  }

	  if(e.target.name === 'documentNumber') {
	    value = value.replace(new RegExp('[^A-Z0-9a-z-/]', 'g'), '');
	  }

	  this.setState({
	    [e.target.name]: value
	  });

	  if (e.target.name === 'documentType' || e.target.name === 'documentNumber') {
	    this.setState({
	      documentStatus: null,
	      documentError: null,
	    });
	  }
	};

  onDiteChange = (index, sloupec, event) => {

    var deti = this.state.deti;
    deti[index][sloupec] = event.target.value;

    if(sloupec === 'rc') {
      //kontrola rc

      var rc = event.target.value;

      if (rc.length === 0) {
        return;
      }

      var rcPattern = /^\s*(\d\d)(\d\d)(\d\d)[ /]*(\d\d\d)(\d?)\s*$/gm;
      var matches = rcPattern.exec(rc);
      if (!matches) {
        deti[index]['error'] = 'Neplatný formát rodného čísla';
        this.setState({deti: deti});
        return;
      }

      rc = rc.replace(/\D/g, '');
      deti[index][sloupec] = rc;

      matches.shift();
      //console.log('matches', matches.shift());
      var [year, month, day, ext, c] = matches;
      var yearN = parseInt(year);
      var monthN = parseInt(month);
      var dayN = parseInt(day);
      if (c === '') {
        yearN += yearN < 54 ? 1900 : 1800;
      } else {
        yearN += yearN < 54 ? 2000 : 1900;
      }

      // k měsíci může být přičteno 20, 50 nebo 70
      if (monthN > 70 && yearN > 2003) {
        monthN -= 70;
      } else if (monthN > 50) {
        monthN -= 50;
      } else if (monthN > 20 && yearN > 2003) {
        monthN -= 20;
      }

      var existDate = new Date(yearN + '/' + monthN + '/' + dayN);
      if (isNaN(existDate.getTime())) {
        deti[index]['error'] = 'Neplatný formát rodného čísla.';
        this.setState({deti: deti});
        return;
      }

      if ((new Date()).getTime() < (new Date(yearN, (monthN - 1), dayN)).getTime()) {
        deti[index]['error'] = 'Nevalidní rodné číslo.';
        this.setState({deti: deti});
        return;
      }

      if (c !== '') {
        // kontrola delitelnosti
        var mod = ("" + year + month + day + ext) % 11;
        if (mod === 10) {
          mod = 0;
        }
        mod += '';
        if (mod !== c) {
          deti[index]['error'] = true;
          this.setState({deti: deti});
          return;
        }
      }

      var actualDate = new Date();
      var actualMonth = actualDate.getUTCMonth() + 1; // cisluje od 0
      var age = actualDate.getUTCFullYear() - yearN; // kolik mi bude az budu mit po narozeninach tenhle rok
      if (actualMonth < monthN) {
        age--;
      } else if (actualMonth === monthN) {
        if (actualDate.getUTCDate() < day) {
          age--;
        }
      }
      if (age >= 18) {
        deti[index]['error'] = 'Děti lze zadat pouze do věku 18 let.';
        this.setState({deti: deti});
        return;
      }
    }
    deti[index]['error'] = false;
    this.setState({deti: deti});


  };

	onClickSendAgain = () => {
	  this.setState({
	    codeSent: false,
	    codeSentInPast: true,
	    message: '',
	  });
	};

	onClickShowCodeInput = () => {
	  this.setState({
	    codeSent: true,
	    message: ''
	  });
	};

  setGold = (gold) => {
    this.setState({
      gold: gold,
    });
  };

  setDeti = (deti_show) => {
    this.setState({
      deti_show: deti_show,
    });
  };

  addDite = () => {
    var deti = this.state.deti;
    deti.push({jmeno:'',rc:''});
    this.setState({
      deti:deti
    });
  };



  delDite = (index) => {
    var deti = this.state.deti;
    delete deti[index];
    this.setState({deti:deti});
  }



	overitDoklad = () => {
	  if (this.state.documentType === 'op') {
	    let rule = /^[1-9][0-9]{8}$/;
	    if (!rule.test(this.state.documentNumber)) {
	      this.setState({
	        documentError: 'Zadané číslo dokladu není správné. ',
	      });
	      return;
	    }
	  }

	  this._myplannClient.kontrolaDokladu(this.state.documentType, this.state.documentNumber).then((response) => {
	    if (response) {
	      this.setState({
	        documentStatus: response.data.data.status,
	      });
	    }
	  }).catch((data) => {
	    if (data.response) {
	      if (data.response.data) {
	        if (data.response.data.exception) {
	          this.setState({
	            hasError: true,
	            message: this.processErrorMessage(data.response.data.exception.message)
	          });
	        }
	      }
	    }

	    throw data;
	  });
	};

	render = () => {
	  // pokud neni uzivatel
	  // presmeruj na login
	  if (!loggedIn()) {
	    return <Redirect to={'/login'}/>;
	  } else if (getUserObject().data._podminky) {
	    // pokud je prihlaseny a odsouhlasil podminky, rovnou na front
	    return <Redirect to={'/'}/>;
	  }

	  var deti = null;
    var that = this;
    var hardError = false;
	  if(this.state.deti && this.state.deti.length > 0 && this.state.deti_show){
	      deti = this.state.deti.map(function(item, index, arr){
	        var errorBox = null;
          if (typeof item.error === 'string' || item.error instanceof String){
            hardError = true;
            errorBox = (<div style={{width: '100%'}} className={'error'}> {item.error} </div> );
          }
	          return (
              <div key={index} className={'gold-document__form'}>
                <div className={'gold-document__form__input'}>
                  <label>Rodné číslo dítěte: </label>
                  <input style={{border: item.error ? '1px solid red' : '1px solid #dddddd'}} onBlur={(event) => that.onDiteChange(index,'rc',event)} type={'text'} defaultValue={item.rc} name={'diterc'} />
                </div>
                <div className={'gold-document__form__input'}>
                  <label>Jméno dítěte: </label>
                  <input onChange={(event) => that.onDiteChange(index,'jmeno', event)} type={'text'} defaultValue={item.jmeno} name={'ditename'} />
                </div>
                {index === arr.length-1 ? <span style={{cursor: 'pointer', marginBottom: '5px'}} className={'ico-add'} onClick={() => that.addDite()}>&nbsp;</span> : <span style={{marginBottom: '5px', cursor: 'pointer'}} className={'delete__cross'} onClick={() => that.delDite(index)}>&nbsp;</span>}
                {errorBox}
              </div>)
        });
    }

	  return (
	    <div>
	      <Loading show={true}/>
	      <Header/>
	      <div className="container">
	        <div className="prvni-prihlaseni-page">
	          <div className="row row--smaller-spaces align-items-start">
	            <div className="col-lg-4 col-md-5">
	              <div className="cell cell--adviser">
	                <Adviser/>
	              </div>
	            </div>

	            <div className="col-lg-8 col-md-7">
	              <ForceUpdateScrollbars
	                className="cell no-padding"
	                autoHeight={true}
	                autoHeightMin={300}
	                autoHeightMax={'calc(100vh - 150px)'}
	                forceupdaterendertimer={1000}
	                identifier={'prvniprihlaseni-scrollbar'}
	              >
	                <div style={{padding: '20px'}} className="scroll-wrapper">
	                <h1 className={!this.state.gold_allowed ? 'hidden' : ''}>Informace – první přihlášení</h1>
	                <div className={'p-padding' + (!this.state.gold_allowed ? ' hidden' : '')}>
	                  <p>
											Já, svým podpisem níže, učiněným v elektronické podobě, <strong>výslovně</strong> potvrzuji, že jsem se v plném rozsahu seznámil(a) s níže uvedenými informacemi a dále, že jsem učinil(a) a udělil(a) osobně (nikoli v zastoupení jinou osobou) níže uvedená prohlášení a souhlasy a současně potvrzuji, že veškeré výše uvedené identifikační údaje mé osoby, příp. mých nezletilých dětí jsou přesnými a pravými údaji identifikujícími moji osobu.
	                  </p>

	                  <p>
	                    <strong>
												Informace dle Nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů (obecné nařízení o ochraně osobních údajů):
	                    </strong>
	                    <ol className={'list'}>
	                      <li>
	                        <strong>totožnost a kontaktní údaje správce:</strong> SAB servis, s.r.o., se sídlem Praha 1 - Nové Město, Jungmannova 748/30, PSČ 11000, IČO: 24704008, zapsaná v OR u Městského soudu v Praze, oddíl C, vložka 167427;
	                        <br/>
													kontaktní údaje pověřence pro ochranu osobních údajů: Miroslav Švenda, Jánský vršek 323/13, 118 00 Praha 1 – Malá Strana, e-mail:   svenda@sabservis.cz, tel: +420257223191;
	                      </li>
	                      <li>
	                        <strong>účely zpracování osobních údajů:</strong>
	                        <ul className={'list'}>
	                          <li>
															poskytování služeb spočívajících ve zprostředkování uzavírání smluv, zejména zprostředkování pojištění, poskytování investičních služeb, zprostředkování spotřebitelských úvěrů;
	                          </li>
	                          <li>
														plnění zákonných povinností správce jako investičního zprostředkovatele a samostatného zprostředkovatele spotřebitelského úvěru vyplývajících ze zvláštních předpisů (např. zákona č. 170/2018 Sb., zákona č. 256/2004 Sb., zákona č. 247/2016 Sb., zákona č. 253/2008 Sb.);
	                          </li>
	                          <li>
															ukládání a uchovávání osobních údajů v databázi klientů (zákazníků) správce a současné předávání a sdílení osobních údajů s příjemci uvedenými pod bodem 4. níže;
	                          </li>
	                          <li>
															vnitřní potřeby, zejména ochrana práv a právem chráněných zájmů správce, sledování kvality služeb a spokojenosti klientů a optimalizace poskytovaných služeb a produktů;
	                          </li>
	                          <li>
															obchodní a (tele)marketingové, zejména nabízení služeb včetně zasílání obchodních sdělení, informování o produktech a službách, a to včetně cílených nabídek produktů a služeb založených na automatizovaném zpracování osobních údajů, vč. profilování.
	                          </li>
	                        </ul>
	                      </li>
	                      <li>
	                        <strong>právní základy zpracování:</strong><br/>
													souhlas subjektu údajů, plnění zákonných (právních) povinností správce (zejm. dle bodu 2. b. výše)
	                      </li>
	                      <li>
	                        <strong>kategorie příjemců osobních údajů:</strong><br/>
													osoby fyzické či právnické tvořící se správcem koncern a třetí osoby spolupracující se správcem; smluvní partneři správce, tj. zejména vázaní zástupci správce; obchodní partneři správce, tj. osoby, které zastupuje správce při uzavírání smluv se subjektem údajů, zejména banky, pojišťovny, investiční společnosti a fondy, penzijní společnosti, poskytovatelé spotřebitelských úvěrů, zdravotní pojišťovny, družstevní záložny; orgány veřejné moci při plnění zákonných povinností správce, např. Česká národní banka, Ministerstvo financí České republiky, Finanční analytický úřad
	                      </li>
	                      <li>
	                        <strong>osobní údaje nebudou předávány do států mimo EU, ani mezinárodní organizaci.</strong>
	                      </li>
	                      <li>
	                        <strong>doba, po kterou budou osobní údaje uloženy (zpracovávány):</strong><br/>
													po dobu využívání služeb správce, vždy však do odvolání souhlasu se zpracováním, v případě údajů potřebných pro plnění zákonných povinností správce po dobu stanovenou příslušnými právními předpisy
	                      </li>
	                      <li>
	                        <strong>upozorňujeme Vás na Vaše právo:</strong>
	                        <ul className={'list'}>
	                          <li>
															požadovat po správci kdykoli přístup k Vašim osobním údajům;
	                          </li>
	                          <li>
															požadovat po správci kdykoli opravu Vašich osobních údajů nebo jejich výmaz, popř. omezení jejich zpracování;
	                          </li>
	                          <li>
															dále máte právo vznést námitku proti zpracování Vašich osobních údajů;
	                          </li>
	                          <li>
															na přenositelnost osobních údajů (resp. požádat o přenos Vašich osobních údajů přímo jinému správci);
	                          </li>
	                          <li>
															kdykoli odvolat Váš souhlas se zpracováním Vašich osobních údajů, aniž je tím ovšem dotčena zákonnost zpracování založená na souhlasu uděleném před jeho odvoláním;
	                          </li>
	                          <li>
															podat stížnost u dozorového úřadu, kterým je Úřad pro ochranu osobních údajů, sídlo: Pplk. Sochora 27, 170 00 Praha 7, www.uoou.cz;
	                          </li>
	                        </ul>
	                      </li>
	                      <li>
	                        <strong>dále Vás upozorňujeme na skutečnost, že poskytnutí Vašich osobních údajů je (smluvním) předpokladem toho, abychom Vám mohli poskytnout poradenské či další služby v plném rozsahu, tzn., že bez poskytnutí osobních údajů může dojít k omezení rozsahu služeb poskytovaných Vaší osobě.</strong>
	                      </li>
	                    </ol>
	                  </p>

	                  <p>
	                    <strong>Souhlas se zpracováním osobních údajů a s šířením obchodních sdělení</strong><br/>
											Prohlašuji, že jsem si v plném rozsahu prostudoval(a) informace poskytnuté mi v tomto dokumentu výše, a že jsem těmto informacím, včetně svých práv v souvislosti se zpracováním mých osobních údajů plně porozuměl(a), přičemž na tomto základě tímto uděluji SAB servis, s.r.o., se sídlem Praha 1 - Nové Město, Jungmannova 748/30, PSČ 11000, IČO: 24704008 svůj svobodný, informovaný a výslovný souhlas s tím, aby veškeré mnou poskytnuté osobní údaje, vč. osobních údajů mého nezletilého dítěte, tj. zejména jméno, příjmení, datum narození, RČ, adresa bydliště, telefonní číslo – mobilní/pevná linka, e-mailová adresa či jiný tzv. elektronický kontakt, včetně tzv. zvláštní kategorie osobních údajů, tj. údajů o mém zdravotním stavu, byly jím zpracovávány k účelům uvedeným výše pod bodem 2. <br/>
	                    <strong>Tento svůj souhlas uděluji správci na dobu neurčitou, tj. až do jeho odvolání.</strong>
	                  </p>

	                  <p>
											Současně uděluji dle zákona č. 480/2004 Sb. správci souhlas k občasnému elektronickému zasílání sdělení (novinky z oblasti financí, informace o činnosti správce, nabídky) na mou e-mailovou adresu či jiný elektronický kontakt. Jsem si vědom(a) svého práva tento souhlas kdykoli odvolat.
	                  </p>

	                  <p>
	                    <strong>Poučení a prohlášení související s přístupem klienta do systému myplann</strong><br/>
											Provozovatelem systému myplann a držitelem autorských práv k němu je společnost SAB servis, s.r.o., Jungmannova 748/30,  110 00 Praha 1, IČ 24704008. SAB servis, s.r.o. jako správce systému umožňuje přístup na tyto stránky svým smluvním partnerům – finančním poradcům, kteří vkládají do systému myplann obsah a spravují klientské sekce v systému. Za obsah vložený do systému myplann odpovídají výhradně jednotliví finanční poradci, čemuž rozumím a beru to na vědomí. Vůči SAB servis, s.r.o. nemám, s výjimkou práv týkajících se mých osobních údajů, které SAB servis, s.r.o. zpracovává, žádná práva z titulu obsahu systému myplann a žádná nebudu jakýmkoli způsobem uplatňovat.
	                  </p>

	                  <p>
											Při používání systému myplann se budu řídit veškerými právními předpisy v souladu s obecně uznávanými zásadami dobrých mravů. Služby systému myplann nebudu zneužívat, nebudu do systému zasahovat, ani se do něj snažit získat přístup jinak než přes určené rozhraní. Při porušení těchto zásad beru na vědomí, že mi může být přístup do systému myplann ukončen.
	                  </p>

	                  <p>
											Podmínkou udělení přístupu do systému myplann je poskytnutí souhlasu se zpracováním svých osobních údajů společnosti SAB servis, s.r.o. Jsem si vědom toho, že mé osobní údaje, které jsem poskytl při přihlášení do systému myplann nebo při jeho používání, budou zpracovávány v souladu s tímto souhlasem se zpracováním osobních údajů.
	                  </p>

	                  <p>
											Výše uvedené prohlášení činím pro účely prvního přístupu do systému myplann a platí i pro veškeré další přístupy do systému. Toto prohlášení, včetně souhlasů obsažených v tomto dokumentu, je právně závazným dokumentem, čehož jsem si vědom.
	                  </p>

	                  <p>
                      Po zaškrtnutí “Souhlas s elektronickou formou komunikace”, vložením čísla dokladu totožnosti a vložením svého telefonního čísla, vyjadřuji výslovný souhlas se zpracováním svých osobních údajů, zasílání obchodních sdělení a s elektronickou formou komunikace a žádám, aby elektronická komunikace, vč. zasílání jakýchkoli přístupových či ověřovacích hesel či kódů, byla tímto správcem s mojí osobou vedena výlučně prostřednictvím těchto elektronických kontaktů:
	                  </p>

	                  <p>
                      mobilní telefonní číslo, které zadávám v dalším kroku udělení souhlasů zde v MyPlann a
	                  </p>

	                  <p>
                      e-mailová adresa: {this._uzivatel.data.username}
	                  </p>

	                  <p>
                      Souhlasím s tím, aby správce spol. SAB servis zpracovával tyto moje elektronické kontakty též za účelem elektronické komunikace s mojí osobou a to včetně možnosti z mé strany elektronicky podepisovat smluvní dokumenty nebo činit jiné právní kroky, vydávat instrukce apod. Výslovně prohlašuji, že se jedná o moje osobní kontaktní údaje, když k předanému mobilnímu číslu a e-mailové adrese mám přístup pouze já osobně a zavazuji se provést nezbytná opatření k tomu, abych zamezil(a) možnosti zneužití těchto komunikačních prostředků třetí osobou.
	                  </p>
	                </div>

	                <div className={'no-gold' + (this.state.gold_allowed ? ' hidden' : '')}>
	                  <h2>Neplatná verze</h2>
	                  <p>
                        Zaslaná verze souhlasu již není platná. Kontaktujte prosím svého poradce a požádejte o vytvoření nového přístupu
	                  </p>
	                </div>

	                <div className={'gold' + (!this.state.gold_allowed ? ' hidden' : '')}>
	                  <label className="btn text-center">
	                    <input type={'checkbox'} onClick={() => this.setGold(!this.state.gold)} checked={this.state.gold} />
                      Souhlas s elektronickou formou komunikace
	                  </label>
	                </div>

	                <div className={'gold-document' + (!this.state.gold ? ' hidden' :'')}>
	                  <h2>Doklad totožnosti</h2>
	                  <div className={'gold-document__form'}>
	                    <div className={'gold-document__form__input'}>
	                      <label>Typ dokladu: </label>
	                      <select name={'documentType'} disabled={true} value={this.state.documentType}>
	                        <option value={'op'}>Občanský průkaz</option>
	                        <option value={'cp'}>Cestovní pas</option>
	                      </select>
	                    </div>
	                    <div className={'gold-document__form__input'}>
	                      <label>Číslo dokladu: </label>
	                      <input type={'text'} name={'documentNumber'} onChange={this.onChange} />
	                    </div>

	                  </div>
	                  <div className={'documentError' + (this.state.documentStatus === 0 ? '' : ' hidden')}>
                      Vámi zadaný doklad je neplatný.
	                  </div>
	                  <div className={'documentError' + (this.state.documentStatus === 2 ? '' : ' hidden')}>
                      Nepodařilo se ověřit doklad. Kontaktujte prosím svého poradce.
	                  </div>
	                  <div className={'documentError' + (this.state.documentStatus === 3 ? '' : ' hidden')}>
						Zadané číslo dokladu se neshoduje s tím, které poradce uvedl při Vaší identifikaci. Kontaktujte prosím svého poradce.
	                  </div>
	                  <div className={'documentError' + (this.state.documentError !== null ? '' : ' hidden')}>
	                    {this.state.documentError}
	                  </div>

                    <div className={'gold' + (!this.state.gold_allowed ? ' hidden' : '')}>
                      <label style={{fontSize: '12px'}} className="btn text-center">
                        <input checked={this.state.deti_show} onClick={() => this.setDeti(!this.state.deti_show)} type={'checkbox'} />
                        Souhlas se zpracováním osobních údajů u nezletiletých dětí.
                      </label>
                    </div>

                    <div className={'gold' + (!this.state.deti_show ? ' hidden' : '')}>
                      <h2 style={{marginTop: '10px'}}>Děti</h2>
                      {deti}
                    </div>

                    <div className={'gold-document__form__btn'}>
                      <input onClick={this.overitDoklad} type={'submit'}
                             className={'btn text-center'}
                             value={'Ověřit'}/>
                    </div>
	                </div>



	                <div className={'validation' + (this.state.documentStatus !== 1 ? ' hidden' :'')}>
	                  <h2>Potvrzení pomocí SMS</h2>
	                  <div className={'validation__sign-box top-space--reset'}>
	                    {this.state && this.state.codeSent ?
	                      <div className="padding--bottom--10 text-weight--400">
													Zadejte, prosím, SMS kód, který jsme Vám zaslali na Vaše telefonní
													číslo {this.state.codeSentTelephone}
	                      </div>
	                      :
	                      ''
	                    }

	                    {this.state && this.state.hasError && this.state.message ?
	                      <div className="padding--bottom--10 text-weight--600 error">
	                        {this.state.message}
	                      </div>
	                      :
	                      ''
	                    }

	                    {this.state.codeSent ?
                        hardError ? <div><span className={'error'}>Pro pokračování opravte chybu v rodném čísle.</span></div> : <div className={'validation__form validation__form--width-width'}>
                            <div className={'validation__form__input'}>
                              <input placeholder={'SMS kód'} type="text" name={'code'}
                                     value={this.state.code} onChange={this.onChange}/>
                            </div>
                            <div className="validation__form__btn">
                              <input type="submit" onClick={this.souhlasUdelen} className={'btn pointer text-center'} value={'Ověřit a souhlasit'} />
                            </div>
                            <div className='width--100--percent text-right text-gray padding--top--5'>
                              <button onClick={this.onClickSendAgain} className='gray-link pointer btn--text-link'>zaslat znovu</button>
                            </div>
                          </div>

	                      :
	                      <div className={'validation__form validation__form--width-width phone'}>
	                        <div className={'validation__form__input'}>
	                          <span className={'plus'}>+</span>
	                          <input className={'predvolba'} placeholder={'Předvolba'} type="text"
	                            name={'telefon_predvolba'} value={this.state.telefon_predvolba}
	                            onChange={this.onChange}/>
	                          <input className={'cislo'} placeholder={'Vaše telefoní číslo'} type="text"
															   name={'telefon_cislo'} value={this.state.telefon_cislo}
															   onChange={this.onChange}/>
	                        </div>
	                        <div className={'validation__form__btn'}>
	                          <input onClick={this.odeslatZnovu} type={'submit'}
															   className={'btn text-center'}
															   value={'Odeslat SMS klíč'}/>
	                        </div>
	                        {this.state.codeSentInPast ?
	                          <div className='width--100--percent text-right text-gray padding--top--5'>
	                            <button onClick={this.onClickShowCodeInput} className='gray-link pointer btn--text-link'>zpět na zadávání kódu</button>
	                          </div>
	                          : ''}
	                      </div>
	                    }

	                    <table className={'top-space--15'}>
	                      <tr>
	                        <td width="50px" className='padding--2 text-dark-gray'>Jméno:</td>
	                        <td className='padding--2 text-dark-gray'>{this.state.name}</td>
	                      </tr>
	                      <tr>
	                        <td className='padding--2 text-dark-gray'>RČ/IČ:</td>
	                        <td className='padding--2 text-dark-gray'>{this.state.rcic}</td>
	                      </tr>
	                    </table>
	                  </div>
	                </div>
	              </div>
	              </ForceUpdateScrollbars>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  );
	}
}

export default PrvniPrihlaseni;
