import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import confirmDialog from "./../../../../utils/confirmDialog";
import fileRenamePromtDialog from "./../../../../utils/fileRenamePromt";
import Lightbox from 'react-images';
import {getContainer} from "../../../../utils/generic";
import {getFileIcon} from "../../../utils/fileIcon";

class FilesList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			documents: (props.documents ? props.documents : []),
			lightboxIsOpen: false,
			currentImage: 0,
			files: this.createFilesList(props.documents),
			disabled: (props.disabled ? props.disabled : false),
		};
		this.state.images = this.createImageList(this.state.files);

		this._client = getContainer().getMyplannClient();
		this._uzivatel = getContainer().getUzivatel();
	};

	createFilesList = (documents) => {
		let files = [];
		if (documents && documents.length > 0) {
			documents.forEach((item) => {
				let preview = process.env.REACT_APP_BASE_URL + '/dokumenty/show-image?id=' + encodeURIComponent(item.id) + '&miniature=1';
				let src = process.env.REACT_APP_BASE_URL + '/dokumenty/show-image?id=' + encodeURIComponent(item.id);
				let icon = getFileIcon(item.dokument.mime);

				if (item.dokument && item.dokument.checkExists === false) {
					icon = 'damaged-file';
				}

				files.push({
					id: item.id,
					caption: item.nazev,
					src: src,
					preview: preview,
					mime: item.dokument.mime,
					icon: icon
				});
			});
		}

		return files;
	};

	createImageList = (files) => {
		let images = [];
		if (files && files.length > 0) {
			files.forEach((item) => {
				if (item.icon === 'image') {
					images.push(item);
				}
			});
		}

		return images;
	};

	openLightbox = (index, event) => {
		event.preventDefault();
		this.setState({
			currentImage: index,
			lightboxIsOpen: true,
		});
	};

	closeLightbox = () => {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		});
	};

	gotoPrevious = () => {
		this.setState({
			currentImage: this.state.currentImage - 1,
		});
	};

	gotoNext = () => {
		this.setState({
			currentImage: this.state.currentImage + 1,
		});
	};

	gotoImage = (index) => {
		this.setState({
			currentImage: index,
		});
	};

	handleClickImage = () => {
		if (this.state.currentImage === this.state.files.length - 1) return;

		this.gotoNext();
	};

	componentDidUpdate = () => {
		let props = this.props;

		let documents = this.state.documents;
		if (props.documents && (props.documents.length !== documents.length || !props.documents.every(function (item, index) {
			return item.id === documents[index].id
		}))) {
			let state = {
				documents: props.documents,
				files: this.createFilesList(props.documents),
			};
			state.images = this.createImageList(state.files);
			this.setState(state);
		}
	};

	smazatDokument = (event, id) => {
		event.preventDefault();

		let confirmOptions = {
			additionalConfirmMessage: 'Položka bude nenávratně smazána.<br/>Chcete-li položku zachovat, zavřete křížkem nebo vyberte <em>zrušit</em>.'
		};

		confirmDialog("Smazání dokumentu", "Opravdu chcete smazat dokument?", "smazat dokument", confirmOptions).then(
			result => {
				this.props.onDelete(id);
				PubSub.publish('smazatPortfolioDalsiDokument', {id: id});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	editovatDokument = (e, index) => {
		let files = this.state.files;

		e.preventDefault();
		e.stopPropagation();

		fileRenamePromtDialog(files[index].caption).then(
			result => {
				if (result.length > 0) {
					this._client.editovatDokumentKeSmlouve(files[index].id, {
						nazev: result.substr(0, 50)
					}).then((r) => {
						if (r) {
							files[index].caption = result.substr(0, 50);
							this.setState({files});
							PubSub.publish('renamePortfolioDalsiDokument', {id: files[index].id, name: result});
						}
					});
				}
			},
			result => {
				// `cancel` callback
			}
		);
	};

	getFilePreview = (item) => {
		if (item.icon === 'image') {
			let index;
			let images = this.state.images;
			images.some(function (elem, i) {
				return elem === item ? (index = i, true) : false;
			});

			return (
				<div className={'image-wrap'}>
					<img
						src={item.preview}
						className={'image pointer'}
						alt={item.caption}
						onClick={(e) => this.openLightbox(index, e)}
					/>
				</div>
			);
		} else {
			return (
				<div className={'image-wrap'}>
					<img
						src={"/img/file-icons/" + item.icon + ".png"}
						className={'icon'}
						alt={item.caption}
					/>
				</div>
			);
		}
	};

	render() {
		return (
			<div>
				{this.state.files && this.state.files.length > 0 ?
					<div className={"document-boxes"}>
						{this.state.files.map((item, index) => {
							return (
								<div className="document-box" key={index}>
									<div className={'document-preview'}>
										<div>
											{this.getFilePreview(item)}
										</div>
										<div>
											<a className="pointer download hide-text"
											   href={this._client.__dokumenty_download_url(item.id)}
											   onClick={(e) => {
												   e.preventDefault();
												   this._client.__dokumenty_download(item.id)
											   }}>Stáhnout soubor</a>

											{this._uzivatel.readAccessOnly() || this.state.disabled ? "" :
												<div>
													<a href="#dummy"
													   className={'pointer edit d-inline-block hide-text'}
													   onClick={(e) => this.editovatDokument(e, index)}>Upravit
														soubor</a>
													< a href="#dummy"
														className="delete pointer d-inline-block delete__cross--small hide-text"
														onClick={(event) => this.smazatDokument(event, item.id)}>Smazat
														soubor</a>
												</div>
											}
										</div>
									</div>
									<div className={'document-name'} title={item.caption}>
										{item.caption}
									</div>
								</div>
							)
						})}

						<Lightbox
							currentImage={this.state.currentImage}
							isOpen={this.state.lightboxIsOpen}
							images={this.state.images}
							onClickImage={this.handleClickImage}
							onClickNext={this.gotoNext}
							onClickPrev={this.gotoPrevious}
							onClickThumbnail={this.gotoImage}
							onClose={this.closeLightbox}
							preventScroll={true}
							showThumbnails={true}
							imageCountSeparator={" / "}
							spinner={() => {
								return (
									<div className={"local-spinner local-spinner--big"}/>
								)
							}}
						/>

					</div>
					:
					<div className={'font-size--11 text-gray--darken'}>
						{this._uzivatel.readAccessOnly() || this.state.disabled ? "Nejsou dostupné žádné fotografie" : ""}
					</div>
				}
			</div>
		);
	}
}

export default FilesList;
