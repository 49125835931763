import React, {Component} from "react";

export default class Textarea extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({});
	}

	render() {
		return (
			<textarea {...this.props} />
		);
	};
}
