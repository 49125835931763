export function getDefaultData(type, disposition = false, flat = false, floor = false, location = false, additional = {}) {
	const defaultData = {};

	defaultData.maxCena = '';
	defaultData.minCena = '';

	defaultData.popis = '';
	defaultData.druh = type;

	if (disposition) {
		defaultData.minDispozice = '';
		defaultData.maxDispozice = '';
	}

	if (flat) {
		defaultData.plochaMin = '';
		defaultData.plochaMax = '';
	}

	if (floor) {
		defaultData.patroMin = '';
		defaultData.patroMax = '';
	}

	if (location) {
		defaultData.lokalita = '';
	}

	Object.keys(additional).forEach((name) => {
		defaultData[name] = false;
	});

	return defaultData;
}