import React, {Component} from "react";
import Input from "./Input";

export default class Dispozice extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: props.name,
			value: props.value,
			textValue: "",
			disabled: props.disabled,
			options: [],
		};

		if ('options' in props && props.options) {
			Object.keys(props.options).forEach((name, index) => {
				this.state.options.push({
					value: name,
					text: props.options[name]
				});
			});
		}

		if (this.getSelectedOption() === null) {
			this.state.textValue = props.value;
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({
			value: props.value
		});

		if (this.getSelectedOption(props.value) === null) {
			this.setState({
				textValue: props.value,
			});
		}
	}

	getSelectedOption = (value = null) => {
		let v = null;
		if (value === null) {
			v = this.state.value;
		} else {
			v = value;
		}

		let ret = this.state.options && v ? this.state.options.filter((item, index) => {
			return item.value === v;
		}) : null;

		if (ret) {
			return ret[0] === undefined ? null : ret[0];
		}
		return null;
	};

	emptyVal = () => {
		return this.state.value === '' || this.state.value === undefined || this.state.value === null;
	};

	onChange = (e) => {
		if (e.target.value === 'vlastni') {
			this.setState({value: this.state.textValue});
			this.props.onChange(e, this.state.textValue);
		} else {
			this.setState({value: e.target.value});
			this.props.onChange(e, e.target.value);
		}
	};

	onInputValueChange = (e) => {
		this.setState({value: e.target.value, textValue: e.target.value});
		this.props.onChange(e, e.target.value);
	};

	render() {
		return (
			<div className={"property-"}>
				<select name={this.state.name} onChange={this.onChange.bind(this)}
						value={this.getSelectedOption() ? this.getSelectedOption().value : 'vlastni'}
						disabled={this.state.disabled}>
					{this.state.options.map((item, index) => {
						return (
							<option key={index} value={item.value}>{item.text}</option>
						);
					})}
				</select>
				{this.getSelectedOption() === null || this.getSelectedOption().value === 'vlastni' ?
					<Input onChange={this.onInputValueChange.bind(this)} name={this.state.name + "_vlastni"}
						   type={'text'} value={this.state.value} disabled={this.state.disabled}/>
					:
					''
				}
			</div>
		);
	};
}
