import React, {Component} from "react";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";

export default class NakupMajetkuPrehled extends Component {

	constructor(props) {
		super(props);
		this._myplannClient = getContainer().getMyplannClient();//props.di('myplannClient');
		this._uzivatel = getContainer().getUzivatel();
		this._dateHelper = getContainer().getDateHelper();
		this.state = {
			item: props.item, // nakup
			majetekKoupe: props.item.nakupyPrehled,
			showHistory: []
		};
	}

	/**
	 * @return MyplannClient
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	/**
	 * @return DateHelper
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	componentDidMount() {
	}

	toggleHistory = (e, index) => {
		let row = this.state.majetekKoupe[index];
		let history = this.state.showHistory;

		if (history.hasOwnProperty(index)) {
			delete history[index];
		} else {
			history[index] = row;
		}

		this.setState({
			showHistory: history
		});
	};

	zrusitNakup = (e, index) => {
		confirmDialog("Zrušení nákupu", "Opravdu chcete zrušit nákup tohoto majetku?", "Zrušit nákup").then(
			result => {
				let row = this.state.majetekKoupe[index];
				this.getMyplannClient().majetekNakupZrusit(row.id_nakupu).then((response) => {
					if (response && response.data.data.success) {
						this.props.onZrusit(row.id_nakupu);
					}
				});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	render = () => {
		if (this.state.majetekKoupe === undefined || this.state.majetekKoupe.length <= 0) {
			return '';
		}

		return (
			<div className={'NakupMajetkuPrehled'}>
				<div>
					{this.state.majetekKoupe.map((item, index) => {
						return (
							<div key={index}>
								<div className={'portfolio__progress-row'} key={index}>
									<div className={'portfolio__progress-row__name'}>
										{item.realitka !== "" ? item.realitka : "[Neznámá RK]"}
									</div>
									<div className={"portfolio__progress-row__graph"}>
										<div title={'Poptávka odeslána'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 100 ? 'done' : 'undone')}>o
										</div>
										<div title={'Poptávka přijata'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 200 ? 'done' : 'undone')}>o
										</div>
										<div title={'Klient kontaktován'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 300 ? 'done' : 'undone')}>o
										</div>
										<div title={'Klient potvrdil zájem'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 400 ? 'done' : 'undone')}>o
										</div>
										<div title={'Nalezena vhodná nemovitost'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 500 ? 'done' : 'undone')}>o
										</div>
										<div title={'Nákup dokončen'}
											 className={"portfolio__progress-row__graph__dot orange " + (item.stav >= 600 ? 'done' : 'undone')}>o
										</div>
									</div>
									<div className={"portfolio__progress-row__show-more"}>
										<span className={'a pointer'} onClick={(e) => {
											this.toggleHistory(e, index);
										}}>
											{this.state.showHistory.hasOwnProperty(index) ?
												<i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>}
											&nbsp; Více informací
										</span>
									</div>
									<div className={"portfolio__progress-row__cancel"}>
										{item.stav !== 600 && !this._uzivatel.readAccessOnly() ?
											<span className="a delete pointer delete__cross--small d-inline-block hide-text"
											   onClick={(e) => {
												   this.zrusitNakup(e, index);
											   }}>Zrušit nakup</span>
											: ""}
									</div>
								</div>
								<div
									className={(this.state.showHistory.hasOwnProperty(index) ? "portfolio__logs-container" : "")}>
									{this.state.showHistory.hasOwnProperty(index) && item.logy_nakupu.map((log_prodeje, index) => {
										return (
											<div className={"portfolio__logs-row"} key={index}>
												<div>
													{log_prodeje.novy_stav_name}
												</div>
												<div>
													{this.getDateHelper().reformatDate(log_prodeje.datum, false, true, 'D. M. YYYY - HH:mm')}
												</div>
												<div>
													{log_prodeje.text}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};
}
