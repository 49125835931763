import React from 'react';
import Header from "../Header";
import Adviser from '../Adviser/Adviser';
import ZmenaHeslaForm from "./ZmenaHeslaForm";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";
import Loading from "../utils/Loading";

const ForcedChangePassword = () => (
	<div>
		<Loading show={false}/>
		<Header/>
		<div className="profil-page">
			<div className="container">
				<div className="row row--smaller-spaces align-items-start">
					<div className="col-lg-4 col-md-5">
						<div className="cell cell--adviser">
							<Adviser/>
						</div>
					</div>
					<div className="col-lg-8 col-md-7">
						<div className="cell cell--full-height">
							<h1 className="padding--bottom--10">Platnost hesla vypršela</h1>
							<ForceUpdateScrollbars
								className="cell--full-height__scrollbox scrollbox--md--disabled"
								autoHeight
								autoHeightMin={0}
								autoHeightMax={1500}
								forceupdaterendertimer={1000}
								identifier={'profil'}
							>
								<p>Při prvním přihlášení do myPLANNu je nutné z bezpečnostních důvodů nastavit nové přihlasovací heslo.</p>
								<br/>

								<ZmenaHeslaForm forcedChange={true} />

							</ForceUpdateScrollbars>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default ForcedChangePassword;