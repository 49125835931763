import React, {Component} from "react";
import {Link} from "react-router-dom";
import {httpDelete, post, put} from "../../utils/Api";
import AssetWizard from "../Portfolio/forms_smlouvy/AssetWizard";
import * as PubSub from "pubsub-js";
import ForceUpdateScrollbars from './../ForceUpdateScrollbars/ForceUpdateScrollbars';
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import alertDialog from "../../utils/alertDialog";

let smlouvyCacheSmall = undefined;
let smlouvyWithoutServicesCache = undefined;
let smlouvyOnlyServicesCache = undefined;

class HomePortfolio extends Component {

	constructor(props) {
		super(props);
		this._uzivatel = getContainer().getUzivatel();
		this.state = {
			perPage: props.perPage !== undefined ? props.perPage : null,
			small: props.small !== undefined ? props.small : false,
			onlyServices: props.onlyServices !== undefined ? props.onlyServices : false,
			withoutServices: props.withoutServices !== undefined ? props.withoutServices : false,
			readAccessOnly: this._uzivatel.readAccessOnly(),
			disabledCloseModal: false,
			disabledDelete: false,
			showNames: false,
		};

		if (this.state.small) {
			this.state.smlouvy = smlouvyCacheSmall;
		} else if (this.state.onlyServices && !this.state.withoutServices) {
			this.state.smlouvy = smlouvyOnlyServicesCache;
		} else if (!this.state.onlyServices && this.state.withoutServices) {
			this.state.smlouvy = smlouvyWithoutServicesCache;
		} else {
			this.state.smlouvy = undefined;
		}

		if (this.state.withoutServices && this.state.onlyServices) {
			console.error("The variables onlyServices and withoutServices can't be set to true at the same time!");
		}

		this._dateHelper = getContainer().getDateHelper();
		this._client = getContainer().getMyplannClient();
	}

	UNSAFE_componentWillMount = function () {
		this._smazatSmlouvu = PubSub.subscribe('smazatSmlouvu', this.assetWizardDelete.bind(this));
		this._smazatPortfolioDalsiDokument = PubSub.subscribe('smazatPortfolioDalsiDokument', this.assetWizardDeleteDokument.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._smazatSmlouvu);
		PubSub.unsubscribe(this._smazatPortfolioDalsiDokument);
	};

	componentDidMount() {
		// if component mounts (i.e. is rendered for the first time), reload
		// the contents
		this.reload();
	};

	reload = () => {
		// fetches new data from API
		/*if (this.state.small) {
			get("/portfolio/portfolio-news").then(response => {
				if (response) {
					if (response.data && response.data.data && response.data.data.db) {
						response.data.data.db.forEach((result) => {
							if (result.type === 'port') {
								this._dateHelper.reformatDates(result);
								this._dateHelper.reformatDates(result.formData);
							} else if (result.type === 'docs') {
								if (result.mime) {
									result.icon = getFileIcon(result.mime);
								}
							}
						});
					}
					smlouvyCacheSmall = response.data.data;
					this.setState({smlouvy: response.data.data});
				}
			});
		} else {*/
			this._client.getPortfolioSmlouvy(this.state.perPage, this.state.small, this.state.onlyServices, this.state.withoutServices).then(response => {
				// updates local state
				// (and causes re-render)
				if (response) {

					let showNames = false;
					let name = null;

					if (response.data && response.data.data && response.data.data.db) {
						response.data.data.db.forEach((result) => {
							this._dateHelper.reformatDates(result);
							this._dateHelper.reformatDates(result.formData);

							if (name !== null && result.jmeno !== name) {
								showNames = true;
							}

							name = result.jmeno;
						});
					}

					if (this.state.small) {
						smlouvyCacheSmall = response.data.data;
					} else {
						if (this.state.onlyServices && !this.state.withoutServices) {
							smlouvyOnlyServicesCache = response.data.data;
						} else if (!this.state.onlyServices && this.state.withoutServices) {
							smlouvyWithoutServicesCache = response.data.data;
						}
					}

					this.setState({smlouvy: response.data.data, showNames});
				}
			});
		//}
	};

	toggleModal = (modalIsOpen, activeItem = null) => {
		if (activeItem && (activeItem.type === null || activeItem.type === 0)) {
			console.warn('no activeItem.type');
			return;
		}

		if (activeItem && activeItem.dokumenty === undefined) {
			activeItem.dokumenty = [];
		}

		if (activeItem && activeItem.id && activeItem.dokumenty.length === 0) {
			this._client.__dokumenty_index(undefined, activeItem.id).then((result) => {
				if (result) {
					activeItem.dokumenty = result.data.data.db;
				}
				this.setState({modalIsOpen, activeItem});
			});
		} else {
			this.setState({modalIsOpen, activeItem});
		}

		modalBodyScrollPrevent(modalIsOpen, 'portfolio');
	};

	closeModal = () => {
		if (!this.state.disabledCloseModal) {
			this.toggleModal(false)
		} else {
			alertDialog("Probíhá nahrávání dokumentů", "Během nahrávání dokumentů nelze okno uzavřít. Vyčkejte než se všechny nové dokumenty uloží.");
		}
	};

	assetWizardDeleteDokument = (name, data) => {
		httpDelete("/dokumenty/index?id=" + data.id).then(
			response => {
				if (response) {
					this.reload();
				}
			}
		);
	};

	assetWizardDelete = (name, data) => {
		if (!this.state.disabledDelete) {
			httpDelete("/portfolio/smlouvy" + (data.id ? "?id=" + data.id : ""), data.data).then(
				response => {
					if (response) {
						this.reload();
						this.toggleModal(false);
					}
				}
			);
		}
	};

	hasItems = () => {
		return this.state.smlouvy !== undefined && this.state.smlouvy.db !== undefined && this.state.smlouvy.db.length !== 0;
	};

	assetWizardSubmit = data => {
		// to ensure that the modal does not close
		// after submit with files
		this.setState({activeItem: data});

		let hideSpinner = false;
		if (data.files && data.files.length > 0) {
			hideSpinner = true;
			PubSub.publish('uploadSmlouvyFilesProgress', {
				data: data,
				target: this,
				percentCompleted: 0
			});
		}

		let dataReformated = JSON.parse(JSON.stringify(data.data));
		this._dateHelper.reformatDates(dataReformated, true, false);
		if (data.id) {
			put("/portfolio/smlouvy" + (data.id ? "?id=" + data.id : ""), dataReformated, hideSpinner).then(
				response => {
					if (data.files && data.files.length > 0) {
						this.setState({disabledCloseModal: true, disabledDelete: true});
						this._client.uploadSmlouvyFiles(data.id, data.type, data.files, hideSpinner).then((r) => {
							this.reload();
							this.toggleModal(false);
							this.setState({disabledCloseModal: false, disabledDelete: false});
							PubSub.publish('refreshAllDocuments');
						});
					} else {
						this.reload();
						this.toggleModal(false);
					}

				}
			);
		} else {
			post("/portfolio/smlouvy", dataReformated, null, hideSpinner).then(
				response => {
					if (response) {
						var portId = response.data.data.id_portfolio;

						if (data.files && data.files.length > 0) {
							this.setState({disabledCloseModal: true, disabledDelete: true});
							this._client.uploadSmlouvyFiles(portId, data.type, data.files, hideSpinner).then((r) => {
								this.reload();
								this.toggleModal(false);
								this.setState({disabledCloseModal: false, disabledDelete: false});
								PubSub.publish('refreshAllDocuments');
							});
						} else {
							this.reload();
							this.toggleModal(false);
						}

					}
				}
			);
		}
	};

	render = () => {
		return (
			<div className={(this.state.small ? 'cell cell--half-height cell--half-height--with-show-more-link' : '')}>
				<h1 className={(this.state.small ? 'cell-title-max-height' : '')}>
					{this.state.onlyServices ? "Služby" : "Portfolio"}

					{this.state.readAccessOnly ?
						''
						:
						<a href={"#dummy"} className='add' onClick={(e) => {
							e.preventDefault();
							this.toggleModal(true);
						}}><i className={"fa fa-plus"}></i></a>
					}
				</h1>

				{this.state.smlouvy === undefined ?
					<div className={'local-spinner'}></div>
					:
					!this.hasItems() ? <div className={'empty-list'}>Nemáte žádné smlouvy</div> : ''
				}

				{this.state.small ?
					<ForceUpdateScrollbars
						className="cell--half-height__scrollbox scrollbox--md--disabled"
						autoHeight
						autoHeightMin={0}
						autoHeightMax={400}
						forceupdaterendertimer={1000}
					>
						<div className={'responsive-table__container padding--right--10'}>
							<div className={"responsive-table responsive-table--first-icon " + (this.props.onlyContractNumber ? '' : 'responsive-table--sm')}>
								{this.state.smlouvy && this.state.smlouvy.db.map((result, index) => {
                  return (
                    <div className="tr table-row pointer" key={result.formData.id}
                         onClick={() => this.toggleModal(true, {
                           id: result.formData.id,
                           type: result.formData.oblast,
                           data: result.formData
                         })}>
                      <div className="td td--width--30">
                        <div
                          className={`icon obl${result.formData.oblast ? result.formData.oblast : 'int'}`}
                          title={result.oblast.nazev}>
                          {result.formData.oblast ? result.oblast.zkratka : ''}
                        </div>
                      </div>
                      <div className="td text-nowrap td--bigger-space">
                        <span
                        className={'break-words'}>{result.formData.cislo_smlouvy}</span>
                      </div>
                        <div className="td td--width--90 text-nowrap td--bigger-space"
                             title={"Datum počátku smlouvy"}>
                          {result.formData.pocatek}
                        </div>
                      {result.formData.nazev.trim().length > 0 ?
                        <div className="td td--bigger-space">
                          {result.formData.nazev}
                        </div> :
                        <div className="td td--bigger-space">
                          {result.formData.produkt}
                        </div>
                      }
                      {result.formData.nazev.trim().length > 0 ?
                        <div className="td "></div> :
                        <div className="td ">
                          <span>{result.formData.producent}</span>
                        </div>
                      }

                        {this.state.showNames === true ?
                          <div className="td">
                            {result.jmeno}
                          </div>
                          : ""}
                    </div>
                  )
									}
								)}
							</div>
						</div>
					</ForceUpdateScrollbars>
					:
					<div className={'responsive-table__container'}>
						<div
							className="responsive-table responsive-table--first-icon responsive-table--second-next-first responsive-table--two-columns">
							{this.state.smlouvy && this.state.smlouvy.db.map((result) => {
									return (
										<div className="tr table-row pointer" key={result.formData.id}
											 onClick={() => this.toggleModal(true, {
												 id: result.formData.id,
												 type: result.formData.oblast,
												 data: result.formData
											 })}>
											<div className="td td--width--30">
												<div
													className={`icon obl${result.formData.oblast ? result.formData.oblast : 'int'}`}
													title={result.oblast.nazev}>
													{result.formData.oblast ? result.oblast.zkratka : ''}
												</div>
											</div>
											<div className="td td--bigger-space">
												Č.&nbsp;<span
												className={'break-words'}>{result.formData.cislo_smlouvy}</span>
											</div>
											<div className="td td--width--90 text-nowrap td--bigger-space"
												 title={"Datum počátku smlouvy"}>
												{result.formData.pocatek}
											</div>
											<div className="td td--width--90 text-nowrap td--bigger-space"
												 title={"Datum ukončení smlouvy"}>
												{result.formData.konec}
											</div>
                      {result.formData.nazev.trim().length > 0 ?
                        <div className="td td--bigger-space">
                          {result.formData.nazev}
                        </div>
                        :
                        <div className="td td--bigger-space">
                          {result.formData.produkt}
                        </div>
                      }
                      {result.formData.nazev.trim().length > 0 ?
                        <div className="td "></div> :
                        <div className="td ">
                          <span>{result.formData.producent}</span>
                        </div>
                      }
											{this.state.showNames === true ?
												<div className="td text-nowrap--big-screen">
													{result.jmeno}
												</div>
												: ""}
										</div>
									)
								}
							)}
						</div>
					</div>
				}

				{this.state.small ? <Link to={`${this.props.match.url}portfolio`} className={"show-more-link"}>Zobrazit
					více</Link> : ""}

				<div className="portfolio-modal">
					<AssetWizard
						onSubmit={this.assetWizardSubmit}
						asset={this.state.activeItem}
						withoutServices={this.state.withoutServices}
						onlyServices={this.state.onlyServices}
						closeModal={this.closeModal.bind(this)}
						modalIsOpen={this.state.modalIsOpen}
					/>
				</div>
			</div>
		);
	};
}

export default HomePortfolio;
