import * as PubSub from "pubsub-js";
import SmlouvyDropzone from "./SmlouvyDropzone";

export default class DokumentyDropzone extends SmlouvyDropzone {

	UNSAFE_componentWillMount = function () {
		this._reload = PubSub.subscribe('reloadDokumentyDropzone', this.reload.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._reload);
	};
}
