import React, {Component} from "react";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";

export default class PronajemMajetkuPrehled extends Component {

	constructor(props) {
		super(props);
		this._myplannClient = getContainer().getMyplannClient();
		this._uzivatel = getContainer().getUzivatel();
		this._dateHelper = getContainer().getDateHelper();
		this.state = {
			item: props.item, // majetek
			majetekPronajmy: props.item.pronajmy,
			showHistory: [],
			msg: null
		};
	}

	/**
	 * @return MyplannClient
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	/**
	 * @return DateHelper
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	componentDidMount() {
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
	}

	toggleHistory = (e, index) => {
		let row = this.state.majetekPronajmy[index];
		let history = this.state.showHistory;

		if (history.hasOwnProperty(index)) {
			delete history[index];
		} else {
			history[index] = row;
		}

		this.setState({
			showHistory: history
		});
	};

	zrusitPronajemRejected = (error, index) => {
		if (error.response && error.response.data && error.response.data.exception) {
			this.setState({
				msg_index: index,
				msg: error.response.data.exception.message
			});
		}
		return undefined;
	};

	zrusitPronajem = (e, index) => {

		confirmDialog("Zrušení pronájmu", "Opravdu chcete zrušit pronájem u tohoto majetku?", "Zrušit pronájem").then(
			result => {
				this.getMyplannClient().majetekPronajemZrusit(this.state.item.id_majetku).then((response) => {
					if (response && response.data.data.success) {
						this.setState({
							majetekPronajmy: []
						});
					}
				}).catch(e => {
					this.zrusitPronajemRejected(e, index);
				});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	render = () => {
		if (this.state.majetekPronajmy === undefined || this.state.majetekPronajmy.length <= 0) {
			return '';
		}
		return (
			<div className={'PronajemMajetkuPrehled'}>
				{this.state.msg !== null ?
					<p className={"error padding--bottom--10"}>Chyba: {this.state.msg}</p>
					:
					''
				}

				{this.state.majetekPronajmy.map((item, index) => {
					return (
						<div key={index}>
							<div className={"portfolio__progress-row"} key={index}>
								<div className={"portfolio__progress-row__name"}>
									<i className={(item.stav === 500 ? "text-weight--600" : "")}>
										{item.typ_pronajmu === "kratkodoby" ? "Krátkodobý pronájem" : "Garantovaný pronájem"}
										{item.stav === 500 ? " od " + item.datum + ": " : ": "}
									</i>
									{item.realitka !== "" ? item.realitka : "[Neznámá RK]"}
								</div>
								<div
									className={"portfolio__progress-row__graph portfolio__progress-row__graph--yellow"}>
									<div title={'Nabídka odeslána'}
										 className={"portfolio__progress-row__graph__dot yellow " + (item.stav >= 100 ? 'done' : 'undone')}>o
									</div>
									<div title={'Nabídka pronájmu přijata'}
										 className={"portfolio__progress-row__graph__dot yellow " + (item.stav >= 200 ? 'done' : 'undone')}>o
									</div>
									<div title={'Klient kontaktován'}
										 className={"portfolio__progress-row__graph__dot yellow " + (item.stav >= 300 ? 'done' : 'undone')}>o
									</div>
									<div title={'Klient potvrdil zájem o pronájem jeho nemovitosti'}
										 className={"portfolio__progress-row__graph__dot yellow " + (item.stav >= 400 ? 'done' : 'undone')}>o
									</div>
									<div title={'Podepsána smlouva o pronájmu'}
										 className={"portfolio__progress-row__graph__dot yellow " + (item.stav >= 500 ? 'done' : 'undone')}>o
									</div>
								</div>
								<div className={"portfolio__progress-row__show-more"}>
									<span className={'a pointer'} onClick={(e) => {
										this.toggleHistory(e, index);
									}}>
										{this.state.showHistory.hasOwnProperty(index) ?
											<i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>}
										&nbsp; Více informací
									</span>
								</div>
								<div className={"portfolio__progress-row__cancel"}>
									{!this._uzivatel.readAccessOnly() ?
										<span className="a delete pointer delete__cross--small d-inline-block hide-text"
										   onClick={(e) => {
											   this.zrusitPronajem(e, index);
										   }}>Zrušit pronájem</span>
										: ""}
								</div>
							</div>
							<div
								className={(this.state.showHistory.hasOwnProperty(index) ? "portfolio__logs-container" : "")}>
								{this.state.showHistory.hasOwnProperty(index) && item.logy_pronajmu.map((log_prodeje, index) => {
									return (
										<div className={"portfolio__logs-row"} key={index}>
											<div>
												{log_prodeje.novy_stav_name}
											</div>
											<div>
												{this.getDateHelper().reformatDate(log_prodeje.datum, false, true, 'D. M. YYYY - HH:mm')}
											</div>
											<div>
												{log_prodeje.text}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	};
}
