// this component wraps the process of adding a new
// portfolio item into one block

import React, {Component} from "react";
import druhySmluv, {getDruhySmluvIDFromName} from "../../../data/druhySmluv";
import {getContainer} from "../../../utils/generic";
import Modal from "../../utils/Modal/Modal";
import confirmDialog from "../../../utils/confirmDialog";

class AssetWizard extends Component {
	constructor(props) {
		super(props);
		this._ismounted = false;
		this.state = {
			onlyServices: props.onlyServices !== undefined ? props.onlyServices : false,
			withoutServices: props.withoutServices !== undefined ? props.withoutServices : false,
			id: null,
			type: null,
			formKey: (new Date()).valueOf(),
			confirmClose: false,
		};
		this._dateHelper = getContainer().getDateHelper();

	}

	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.asset) {
			this.setState({
				type: nextProps.asset.type,
				id: nextProps.asset.id
			});
		} else {
			// Firstly we need close the modal window and then we can set data to null.
			setTimeout(() => {
				// fixed - Can't call setState (or forceUpdate) on an unmounted component.
				if (this._ismounted) {
					this.setState({
						id: null,
						type: this.props.onlyServices ? "10" : null, // 10 is identifier for <Sluzby>
						formKey: (new Date()).valueOf(),
						confirmClose: false,
					});
				}
			}, 300);
		}
	}

	selectAssetType = type => {
		this.setState({type});
	};

	subformSubmit = (data, files) => {
		let d = {
			type: this.state.type,
			id: this.state.id,
			files,
			data
		};

		this.setState({
			confirmClose: false,
		});

		this.props.onSubmit(d);
	};

	getDruhySmluv = (withoutServices = false, onlyServices = false) => {
		return druhySmluv.filter(function (i) {
			if (i.id === '10' && withoutServices === true) {
				return false;
			} else if (i.id !== '10' && onlyServices === true) {
				return false;
			}

			return true;
		});
	};

	closeModal = () => {
		if (this.state.confirmClose) {
			confirmDialog("Neuložené změny", "Opravdu si přejete zavřít okno bez uložení změn?", "zavřít okno").then(
				result => {
					this.setState({
						confirmClose: false,
					});

					this.props.closeModal();
				},
				result => {
				}
			);
		} else {
			this.props.closeModal();
		}
	};

	onFormChange = () => {
		if (!this.state.confirmClose) {
			this.setState({
				confirmClose: true,
			})
		}
	};

	renderList = () => {
		return (
			<div>
				<h1>Přidat</h1>
				<ul className="links-center">
					{this.getDruhySmluv(this.state.withoutServices, this.state.onlyServices).map(i => (
						<li key={i.id}>
							<span className="links-center-a" onClick={() => this.selectAssetType(i.id)}>{i.name}</span>
						</li>
					))}
				</ul>
			</div>
		);
	};

	render() {
		let type = getDruhySmluvIDFromName(this.state.type);
		const Form = type !== null && druhySmluv[type].form;

		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={this.closeModal}
			>
				{Form ? <Form onSubmit={this.subformSubmit} asset={this.props.asset} key={this.state.formKey}
							  onFormChange={this.onFormChange.bind(this)}/> : this.renderList()}
			</Modal>
		);
	}
}

export default AssetWizard;
