import React from 'react';
import PropTypes from 'prop-types';
import {confirmable, createConfirmation} from 'react-confirm';
import Modal from "../components/utils/Modal/Modal";
import {modalBodyScrollPrevent} from "./generic";

const ConfirmDialog = ({show, proceed, dismiss, cancel, confirmationTitle, confirmationText, confirmationBtn, options}) => {

	modalBodyScrollPrevent(show, "confirmDialog");

	return (
		<Modal
			onRequestClose={dismiss}
			isOpen={show}
		>
			<div className={'modal__confirm'}>
				<h1 className={'modal__confirm__title'}>{confirmationTitle}</h1>
				<div className={'modal__confirm__container'}>
					<p>{confirmationText}</p>

					<div className={'modal__confirm__buttons d-flex'}>
						<button className={'modal__confirm__btn'} onClick={() => proceed()}
								autoFocus>{confirmationBtn}</button>
						<button className={'modal__confirm__btn modal__confirm__btn--gray'}
								onClick={() => cancel()}>zrušit
						</button>
					</div>

					{options.additionalConfirmMessage ?
						<p className={'modal__confirm__danger'}
						   dangerouslySetInnerHTML={{__html: options.additionalConfirmMessage}}></p>
						:
						null
					}
				</div>
			</div>

		</Modal>
	);
};

ConfirmDialog.propTypes = {
	show: PropTypes.bool,
	proceed: PropTypes.func,
	cancel: PropTypes.func,
	dismiss: PropTypes.func,
	confirmationTitle: PropTypes.string,
	confirmationText: PropTypes.string,
	confirmationBtn: PropTypes.string,
	options: PropTypes.object
};

// create confirm function
const confirmDialog = createConfirmation(confirmable(ConfirmDialog));

// This is optional. But I recommend to define your confirm function easy to call.
export default function (confirmationTitle, confirmationText, confirmationBtn, options = {}) {
	// You can pass whatever you want to the component. These arguments will be your Component's props
	return confirmDialog({confirmationTitle, confirmationText, confirmationBtn, options});
}
