import React, {Component} from "react";
import 'moment/locale/cs';
import Textarea from "../../utils/Textarea";
import Input from "../../utils/Input";
import ReactDOM from "react-dom";
import FormErrors from "../../utils/FormErrors";
import {getDefaultData} from "./formHelpers";

export default class NemovitostJakoInvestice extends Component {
	constructor(props) {
		super(props);
		this.state = this.emptyData();
		this.disabled = false;
		if (props.asset) {
			this.disabled = (props.asset.data && props.asset.data.hasOwnProperty("dokoncen") ? props.asset.data.dokoncen || this.getUzivatel().readAccessOnly() : this.getUzivatel().readAccessOnly());

			let dates = {};
			this.state = Object.assign(dates, this.emptyData(), {...props.asset.data});
		}

		this.validationErrors = [];
	}

	emptyData = () => {
		return getDefaultData("nemov", false, false, false, false);
	};

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		return this.props.dateHelper;
	};

	/**
	 * @returns {Uzivatel}
	 */
	getUzivatel = () => {
		return this.props.uzivatel;
	};

	submit = e => {
		e.preventDefault();
		let validateErrors = [];

		if (this.state.minCena !== "" && this.state.maxCena !== "") {
			if (parseInt(this.state.minCena, 10) > parseInt(this.state.maxCena, 10)) {
				validateErrors.push("Minimální cena nemovitosti musí být nižší než maximální cena")
			}
		}

		if (validateErrors.length === 0) {
			let data = this.state;
			this.props.onSubmit(data);
		} else {
			const element = ReactDOM.findDOMNode(this);
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth'
			});
		}

		this.validationErrors = validateErrors;
		this.setState({});
	};

	onChange = e => {
		this.props.onChange(e, this);
	};

	onFieldChange = (name, value) => {
		this.props.onFieldChange(name, value, this);
	};

	onChangeOnlyNumbers = e => {
		const re = /^[0-9\b\s]+$/;
		let value = e.target.value.replace(/\s+/g, "");
		if (value === '' || re.test(value)) {
			this.props.onFieldChange(e.target.name, value, this);
		}
	};

	showFormatedNumber = (number) => {
		if (number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		} else {
			return "";
		}
	};

	render() {
		return (
			<div>
				<form className={'form'} onSubmit={this.submit}>
					<h1>Nemovitost jako investice</h1>
          <div className={'form-description'}>
          <p>Automatické vyhledávání nemovitosti na základě parametrů ze všech nabídek RK, přímých majitelů i dražeb.
            O nejlepších příležitostech se dozvíte jako první. Následné technické a právní prověření nemovitosti.</p>
              <p>Na základě specifikace hledané nemovitosti zajistíme:
                <ul>
                  <li>Okamžité upozornění na nejlepší nabídky ihned po jejich uveřejnění. Rychlost je pro nalezení výhodné nabídky rozhodující.</li>
                  <li>Vyjednání slevy a poradenství. Realitní specialista je na vaší straně a profesionálními argumenty přispěje k dosažení nejnižší možné ceny a poradí vybrat správnou nemovitost. Pro ušetření času Vás na prohlídkách zastoupíme a zašleme protokol.</li>
                  <li>Prověření nemovitosti. Nekupujte zajíce v pytli. Naši technici se zaměří na skryté technické vady. Z&nbsp;technické inspekce obdržíte inspekční protokol. Právní oddělení zatím provede kontrolu smluvní dokumentace.</li>
                  </ul>
                </p>
              <p>Cena služby - vyplňte poptávkový formulář, odešlete realitní kanceláři a následně Vás bude kontaktovat s cenou za službu (cena se obvykle pohybuje okolo 1% z kupní ceny)</p>
          </div>
          <FormErrors errors={this.validationErrors}/>
					<input type="hidden" name="druh" value={this.state.druh}/>
					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<tbody>

						<tr>
							<td>
								Min. cena
							</td>
							<td>
								<Input onChange={this.onChangeOnlyNumbers} type="text" name="minCena" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.minCena)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> Kč</span>
							</td>
						</tr>
						<tr>
							<td>
								Max. cena
							</td>
							<td>
								<Input onChange={this.onChangeOnlyNumbers} type="text" name="maxCena" maxLength={11}
									   autoComplete={"off"}
									   value={this.showFormatedNumber(this.state.maxCena)} disabled={this.disabled}
									   className="nfthousands"/>
								<span> Kč</span>
							</td>
						</tr>
						<tr>
							<td>Doplňující popis nemovitosti</td>
							<td><Textarea onChange={this.onChange} name="popis"
										  value={this.state.popis} disabled={this.disabled}/></td>
						</tr>

						{this.getUzivatel().readAccessOnly() || this.disabled ? '' :
							<tr className={'conrol-row'}>
								<td className={'td-delete'}>
									{this.state.id_nakupu !== undefined ?
										<span onClick={() => this.props.zrusitNakup(this.state.id_nakupu)}
										   className={'delete pointer'}>
											smazat poptávku
										</span>
										: ''
									}
								</td>
								<td>
									<input type="submit" className="btn" value="uložit"/>
								</td>
							</tr>
						}

						</tbody>
					</table>
				</form>
			</div>
		);
	}
}
