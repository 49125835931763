import React, {Component} from "react";
import {get, httpDelete, post} from "../../utils/Api";
import confirmDialog from "../../utils/confirmDialog";
import alertDialog from "../../utils/alertDialog";

class ComplaintsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			obsah: this.props.obsah ? this.props.obsah : "",
			complaints: []
		};
	}

	UNSAFE_componentWillMount = function () {
		this.reload();
	};

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value});
		this.props.onChange();
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (this.state.obsah.match(/^(\s)*$/gi)) {
			this.setState({
				hasError: true,
				message: "Zadejte prosím text zpětné vazby."
			});
		} else {
			post('/complaint/submitComplaint', {
				obsah: this.state.obsah
			}).then((res) => {
				if (res) {
					this.setState({
						hasError: false,
						message: "Zpětná vazba byla úspěšně uložena.",
						obsah: "",
					});

					this.reload();
					this.props.onSubmit();
				}
			});
		}
	};

	onClick = (complaintId) => {
		confirmDialog("Vzetí zpětné vazby zpět", "Opravdu chcete vzít zpětnou vazbu zpět?", "vzít zpět").then(
			result => {
				httpDelete("complaint/deleteComplaint?complaintId=" + complaintId).then((res) => {
					if (res) {

						let message = "";

						if (res.data.data[0] === "OK") {
							this.reload();
						} else if (res.data.data[0] === "NOT FOUND") {
							message = "Požadovaná zpětná vazba nebyla nalezena.";
						} else if (res.data.data[0] === "CANNOT BE DELETED") {
							message = "Požadovanou zpětnou vazbu již nelze vzít zpět, protože se zpracovává nebo již byla vyřízena.";
						} else {
							message = "Při vzetí zpětné vazby zpět došlo k chybě.";
						}

						if (message !== "") {
							alertDialog(message, "");
						}

						this.setState({
							message: "",
						});

					}
				});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	reload = () => {
		get("complaint/loadComplaints").then((res) => {
			if (res) {
				this.setState({
					complaints: res.data.data
				});
			}
		});
	}

	render = () => {
		var thisObject = this;
		var complaintsList = this.state.complaints.map(function (item) {
			return (
				<div key={item.id} className='complaint'>
					<div className={'complaint__content'}>
						{item.obsah.split('\n').map((lineText, lineKey) => {
							return <span key={lineKey}>{lineText}<br/></span>
						})}
					</div>
					<div className={'complaint__informations'}>
						<div>
							{item.odeslano}
						</div>

						<div>
							{thisObject.capitalizeFirstLetter(item.stav)}
						</div>

						<div>
							{item.stav === 'přijata' ? (
								<div className={'td'}>
									<span className={'pointer underline-hover'} onClick={() => {
										thisObject.onClick(item.id)
									}}>Vzít zpět</span>
								</div>
							) : ''}
						</div>
					</div>
				</div>)
		});

		return (
			<div>
				<div className="complaint-form">

					<h2>Co bych mohl zlepšit a s čím jste byli spokojeni?</h2>

					<form onSubmit={this.onSubmit}>
						{this.state.message ?
							<div
								className={"padding--bottom--10 " + (this.state.hasError ? 'error' : 'success')}>{this.state.message}
							</div>
							:
							''
						}

						<div>
							<textarea onChange={this.onChange} name={'obsah'} className={"width--100--percent"}
									  value={this.state.obsah}/>
						</div>
						<div>
							<input type="submit" name={"save"} className={'btn submit'} value={'odeslat'}/>
						</div>
					</form>
				</div>

				<div className="complaint-list">
					{this.state.complaints.length > 0 ? (
						<div>
							<h2>&nbsp;</h2>
							{complaintsList}
						</div>
					) : ''}
				</div>
			</div>
		)
	};
}

export default ComplaintsForm;
