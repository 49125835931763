import AppartmentForm from "./../components/Portfolio/forms/Appartment";
import LandForm from "./../components/Portfolio/forms/Land";
import AutoForm from "./../components/Portfolio/forms/Auto";
import KomodForm from "./../components/Portfolio/forms/Komod";
import ChataForm from "./../components/Portfolio/forms/Chata";
import DumForm from "./../components/Portfolio/forms/Dum";

const propertyAssetsTypes = [
	{id: "byt", name: "Byt", form: AppartmentForm},
	{id: "dum", name: "Dům", form: DumForm},
	{id: "chata", name: "Chata", form: ChataForm},
	{id: "pozem", name: "Pozemek", form: LandForm},
	{id: "auto", name: "Auto/moto", form: AutoForm},
	{id: "komod", name: "Komodita", form: KomodForm}
];

export default propertyAssetsTypes;

export function getPropertyAssetTypeIDFromName(name) {
	let type = null;
	propertyAssetsTypes.forEach((data, index) => {
		if (data.id === name) {
			type = index;
		}
	});
	return type;
}