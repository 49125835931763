import {getFileIcon} from "../utils/fileIcon";

export default class DropzoneFile {
	constructor(id = null, file) {
		this.id = id;
		this.file = file;
		let fileName = this.removeExt(file.name);
		this.name = fileName.substring(0, 50);
	}

	removeExt(name) {
		let arr = name.split('.');
		if (arr.length > 1) {
			arr.pop();
		}
		return arr.join('.');
	}

	getCustomName() {
		return this.name;
	}

	setCustomName(name: string) {
		this.name = name.substring(0, 50);
	}

	getId() {
		return this.id;
	}

	getFile() {
		return this.file;
	}

	getName() {
		return this.file.name;
	}

	getSize() {
		return this.file.size;
	}

	getMimeType() {
		return this.file.type;
	}

	getPreview() {
		return this.file.preview;
	}

	isNew() {
		return this.id === null
	}

	getIcon() {
		if (!this.icon) {
			this.icon = getFileIcon(this.getMimeType());
		}

		return this.icon;
	}
}