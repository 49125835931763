import React, {Component} from "react";
import {get} from "../../utils/Api";
import DetailZaznamu from "../DetailZaznamu/DetailZaznamu";
import SendSms from "../Validation/SendSms";
import * as PubSub from "pubsub-js";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import Modal from "../utils/Modal/Modal";
import DetailZaznamuSjednani from "../DetailZaznamu/DetailZaznamuSjednani";

let dataCache = undefined;

class Events extends Component {

	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this._ismounted = false;
		this.state = {
			contractSMSSignToken: props.contractSMSSignToken !== undefined ? props.contractSMSSignToken : null,
			smlouvaInfo: undefined,
			linkToken: null,
			data: dataCache,
      showHtmlDetail: true,
      zaznamPodepsan: false,
      page: 1,
      totalPages: 1
		};
	};

	UNSAFE_componentWillMount = function () {
		this._recordSigned = PubSub.subscribe('recordSigned', this.recordSigned.bind(this));
		this._ismounted = true;
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._recordSigned);
		this._ismounted = false;
	};

	componentDidMount() {
		this.reload();
    this.setState({
      showHtmlDetail: true
    });
		if (this.state.contractSMSSignToken !== null) {
			this._client.getContractData(this.state.contractSMSSignToken).then(response => {
				if (response) {
					if (response.data && response.data.data && response.data.data.smlouvaInfo) {
						this.toggleModal(true, response.data.data.smlouvaInfo.id);
					} else {
						this.toggleModal(true, null);
					}
				}
			});
		}
	};

	toggleModal = (modalIsOpen, eventId = null) => {
		this.setState({modalIsOpen, eventId});

		if (modalIsOpen && eventId !== null) {
			get('/udalost/detailZaznamu?id=' + eventId).then(response => {
				if (response) {

          let page = this.state.page;
          let totalPages = this.state.totalPages;
          let smlouvaInfo = response.data.data.props;
          if(smlouvaInfo) {
            console.log(Array.isArray(smlouvaInfo.onlineSjednani));
            if(Array.isArray(smlouvaInfo.onlineSjednani)) {
              totalPages = smlouvaInfo.onlineSjednani.length + 1;
            }
            if(smlouvaInfo.podepsana && !smlouvaInfo.podepsanaVcSmluv && Array.isArray(smlouvaInfo.onlineSjednani)) {
              // klient podepsal smlouvu ale skoncil nekde na strance
              smlouvaInfo.onlineSjednani.forEach((val, index) => {
                if(!val.podepsal_klient && page === 1) {
                  page = index + 2;
                }
              })
            }
          }


					this.setState({
						smlouvaInfo: response.data.data.props,
            totalPages: totalPages,
            page: page
					});
				}
			});
		} else {
			setTimeout(() => {
				if (this._ismounted) {
					this.setState({
						smlouvaInfo: undefined,
						linkToken: undefined
					});
				}
			}, 200);
		}

		modalBodyScrollPrevent(modalIsOpen, "events");
	};

	reload = () => {
		get('/udalost/events-one').then(response => {
			if (response) {
				dataCache = response.data.data.api;
				this.setState({data: response.data.data.api});
			} else {
				dataCache = null;
				this.setState({data: null});
			}
		});
	};

	recordSigned = function (msg, data) {
		this.reload();
	};

	hasItems = () => {
		return this.state.data !== undefined && this.state.data.length !== 0;
	};

  onSmsVerified = () => {
    this.setState({
      showHtmlDetail: false,
      zaznamPodepsan: true,
      page: this.state.page < this.state.totalPages ? this.state.page + 1 : this.state.page
    });
	};

	render = () => {

	  let self = this;

		return (
			<div className="events">
				{!this.state.data ?
					<div className={'local-spinner'}></div>
					:
					!this.hasItems() ? <div className={'empty-list'}>Nemáte žádné záznamy z jednání.</div> : ''
				}
				<div className={'responsive-table__container'}>
					<table className="responsive-table">
						<tbody>
						{this.state.data && this.state.data.map((result) => {
								return (
									<tr className={"table-row pointer" + (result.podepsanaVcSmluv ? ' tr--green' : ' tr--red')}
										key={result.id}
										onClick={() => this.toggleModal(true, result.id)}
									>
										{result.datumOdeslani !== null ?
										<td className='td--width--90 text-nowrap'>
											{result.datumOdeslani}
										</td>
											: ''}
										<td className='td width--100--percent' colSpan={(result.datumOdeslani !== null ? '1' : '2')}>
											<strong>
												{result.oblast}
											</strong>
										</td>
									</tr>
								)
							}
						)}
						</tbody>
					</table>
				</div>

				<div className="events-modal">
					<Modal
						isOpen={this.state.modalIsOpen}
						onRequestClose={() => this.toggleModal(false)}
					>
						{this.state.smlouvaInfo === undefined ? <div className={"local-spinner"}></div> :
							<div>
								{this.state.smlouvaInfo ?
									<div className={"validation"}>
                    {this.state.page === 1 ? <>

                      <h1 className="padding--bottom--20">{this.state.totalPages > 1 ? <>Podpis smluvní dokumentace - {this.state.page} ze {this.state.totalPages}</> : <>Potvrzení nového záznamu</>}</h1>
                      <DetailZaznamu
                        smlouvaInfo={this.state.smlouvaInfo} showHtmlDetail={this.state.showHtmlDetail}
                      />
                      <div className={"validation__sign-box"}>
                        <SendSms token={this.state.smlouvaInfo.linkToken} inModal={false} onSmsVerified={this.onSmsVerified.bind(this)}/>
                      </div>
                    </> : <>
                      <h1 className="padding--bottom--20">Podpis smluvní dokumentace - {this.state.page} ze {this.state.totalPages}</h1>
                      <DetailZaznamuSjednani key={this.state.page} onSmsVerified={this.onSmsVerified.bind(this)} token={this.state.smlouvaInfo.linkToken} zaznamPodepsan={this.state.smlouvaInfo.podepsana || this.state.zaznamPodepsan} podepsano={this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].podepsal_klient} cislo_smlouvy={this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].cislo_smlouvy}/>
                    </>}

									</div>
									:
									<div className={"modal__error-title"}>
										<h1>Záznam nebyl nalezen nebo byl již podepsán.</h1>
									</div>
								}
                {(this.state.smlouvaInfo && this.state.smlouvaInfo.podepsanaVcSmluv && this.state.page > 1) && <a style={{float:'left'}} className={'btn'} onClick={()=>{self.setState({page: this.state.page-1}); }} >Zobrazit předchozí záznam</a>}
                {(this.state.smlouvaInfo && this.state.smlouvaInfo.podepsanaVcSmluv && this.state.page < this.state.totalPages) && <a style={{float:'right'}} className={'btn'} onClick={()=>{self.setState({page: this.state.page+1}); }} >Zobrazit další záznam</a>}
                <div style={{clear: 'both'}} />
							</div>
						}
					</Modal>
				</div>

			</div>
		);
	};
}

export default Events;
