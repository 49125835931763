import React from "react";
import DokumentyDropzone from "../DokumentyDropzone";
import BaseDokumentyForm from "./BaseDokumentyForm";
import ForceUpdateScrollbars from "../../ForceUpdateScrollbars/ForceUpdateScrollbars";

class DokumentyForm extends BaseDokumentyForm {
	constructor(props) {
		super(props);
		//this.state = emptyData;

		// if asset is in the props
		// fill the state with it
		if (props.asset) {
			// Object.assign "merges" objects (try it yourself)
			this.state = Object.assign({}, {...props.asset.data});
			this._dokumenty = props.asset.dokumenty;
		}
		this.state = {
			disabledSubmitBtn: props.disabledSubmitBtn !== undefined ? props.disabledSubmitBtn : false,
            openFileWizard: props.openFileWizard
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.asset) {
			this._dokumenty = nextProps.asset.dokumenty;
			this.setState({
				...nextProps.asset.data
			});
		}

		if (nextProps.hasOwnProperty("disabledSubmitBtn")) {
			this.setState({
				disabledSubmitBtn: nextProps.disabledSubmitBtn,
			});
		}
	}

	render() {
		return (
			<form className="form" onSubmit={this.submit}>
				<div className={'contract-documents__container'}>
					<ForceUpdateScrollbars
						className="documents__scrollbox"
						autoHeight
						autoHeightMin={0}
						autoHeightMax={234}
						forceupdaterendertimer={1000}
					>
						<div className={'contract-documents__scrollbox__container'}>
							<DokumentyDropzone onDrop={this.onDrop.bind(this)}
											   dropzoneListChanged={this.props.dropzoneListChanged}
											   key={'aloha'}
                                               openFileWizard={this.state.openFileWizard}
							/>
						</div>
					</ForceUpdateScrollbars>
				</div>
				<table className="modal-form-table width--100--percent top-space--5">
					<tbody>
					<tr className={"control-row"}>
						<td className={'text-right padding--right--reset'}>
							<button type="submit" className="btn" disabled={this.state.disabledSubmitBtn}>
								nahrát <span className={"modal-form-table__button-350-hide-text"}>dokumenty</span>
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</form>
		);
	}

	getDokumenty = () => {
		return this._dokumenty;
	};
}

export default DokumentyForm;
