import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

export default class ForceUpdateScrollbars extends Component {

	constructor(props) {
		super(props);
		this._ismounted = false;

		this.state = {
			updated: 0,
			identifier: props.identifier !== undefined ? props.identifier : null
		};

		this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;
		this.updateComponentTimer();
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	updateComponentTimer() {
		if (this._ismounted && this.state.updated < 5) {
			setTimeout(() => {
				if (this._ismounted) {
					this.setState({updated: this.state.updated + 1});
				}
			}, this.props.forceupdaterendertimer);
		}
	}

	handleUpdate = (values) => {
		if (values.scrollHeight > values.clientHeight) {
			this.refs.scrollbar.container.classList.add("scrollbox--right-padding");
		} else {
			this.refs.scrollbar.container.classList.remove("scrollbox--right-padding");
		}
	};

	render() {
		let props = {...this.props};

		return (
			<Scrollbars
				renderTrackHorizontal={props => <div {...props} className="d-none"/>}
				onUpdate={this.handleUpdate}
				ref={"scrollbar"}
				{...props}
			/>
		);
	}
}