import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {loggedIn, login, logout, getCSRFToken} from "../../utils/Api";
import * as PubSub from "pubsub-js";
import {getContainer} from "../../utils/generic";
import {parse_query_string} from "../../utils/urlparse";

class Login extends Component {
	constructor(props) {
		super(props);
        let qs = {};
        if (props.location) {
            qs = parse_query_string(props.location.search.substring(1));
        }
        let signInToken = qs.signInToken;

		this.state = {
			username: "",
			password: "",
			redirectToReferrer: false,
			hasError: false,
			message: (props.message ? props.message : null),
			redirectToZapomenuteHeslo: false,
      csrfToken: '',
		};
		this._client = getContainer().getMyplannClient();

        if (signInToken) {
        	let _self = this;
            if (loggedIn()) {
            	logout(false).then(() => {
            		_self.singInTokenLoginLogic(signInToken);
				});
            } else {
            	_self.singInTokenLoginLogic(signInToken);
			}
        }

    getCSRFToken().then((response) => {
      this.state.csrfToken = response.data.data.token;
    }).catch((e) => {

    });
	}

	componentDidCatch(error, info) {
		this.setState({hasError: true});
	}

	UNSAFE_componentWillMount = function () {
		this._requestFinishedWithError = PubSub.subscribe('requestFinishedWithError', this.requestFinishedWithError.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._requestFinishedWithError);
	};

    singInTokenLoginLogic = (signInToken) => {
        login(undefined, undefined, signInToken).then(() => {
            window.location.href = "/"; // nechci aby viděl parametr v URL
        }).catch((e) => {
            window.location.href = "/login";
        });
    };

	requestFinishedWithError = (name, data) => {
		if (data.error) {
			this.setState({hasError: true});
		}
	};

	zapomenuteHeslo = () => {
		this.setState({
			redirectToZapomenuteHeslo: true
		});
	};

	login = e => {
		e.preventDefault();
		if (this.state.username && this.state.password) {
			login(this.state.username, this.state.password, undefined, this.state.csrfToken).then((response) => {
				if (response && response.data) {
				  if (response.data.data.csrfNotValid) {
            getCSRFToken().then((response) => {
              this.setState({
                csrfToken: response.data.data.token
              });
              // this.state.csrfToken = response.data.data.token;
            }).catch((e) => {

            });
            this.setState({
              message: "Formulář již nebyl platný. Přihlaste se prosím znovu.",
              hasError: true,
            });
          } else {
            this.setState({redirectToReferrer: true});
          }
				} else {
					this.setState({message: "Zadejte správné přihlašovací jméno a heslo"});
				}
				return response;
			}).catch((e) => {
				this.setState({message: "Zadejte správné přihlašovací jméno a heslo"});
			});
		}
	};

	onChange = e => {
		this.setState({[e.target.name]: e.target.value});
	};

	render = () => {
		if (this.state.redirectToReferrer) {
			return <Redirect to={"/"}/>;
		}

		if (this.state.redirectToZapomenuteHeslo) {
			return <Redirect to={"/zapomenute-heslo"}/>
		}

		return (
			<form className="login-form__form" onSubmit={this.login}>
				{this.state.hasError && this.state.message ?
					<div className="login-form__alert">
						{this.state.message}
					</div>
					:
					''
				}

				{this.props.infoMsg && this.state.message ?
					<div className="login-form__alert login-form__alert--green">
						{this.state.message}
					</div>
					:
					''
				}

				<div>
					<input placeholder="Přihlašovací jméno" type="text" name="username" onChange={this.onChange}/>
					<input placeholder="Heslo" type="password" name="password" onChange={this.onChange}/>
					<div className={'login-submit-btns-wrapper'}>
						<table>
							<tbody>
							<tr>
								<td>
									<input className="btn" type="submit" value="Přihlásit se" name={"signin"}
										   onClick={this.login}/>
								</td>
								<td className={'zapomenute-heslo-btn-wrapper'}>
									<span className="pointer zapomenute-heslo-btn" onClick={this.zapomenuteHeslo}>Zapomenuté
										heslo</span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</form>
		);
	};
}

export default Login;
