import React, {Component} from "react";
import {get, httpDelete, post, put} from "../../utils/Api";
import AssetWizard from "./forms/AssetWizard";
import * as PubSub from "pubsub-js";
import ProdejMajetkuPrehled from "./majetek/ProdejMajetkuPrehled";
import PronajemMajetkuPrehled from "./majetek/PronajemMajetkuPrehled";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import alertDialog from "../../utils/alertDialog";

let dataCache = undefined;
let firstColumnWidthCache = 30;

class Majetek extends Component {

	constructor(props) {
		super(props);
		this._uzivatel = getContainer().getUzivatel();
		this.state = {
			perPage: props.perPage ? props.perPage : null,
			readAccessOnly: this._uzivatel.readAccessOnly(),
			majetek: dataCache,
			firstColumnWidth: firstColumnWidthCache,
			modalIsOpen: false,
			showNames: false,
		};
		this._dateHelper = getContainer().getDateHelper();
		this._client = getContainer().getMyplannClient();
	}

	UNSAFE_componentWillMount = function () {
		this._smazatMajetek = PubSub.subscribe('smazatMajetek', this.assetWizardDelete.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._smazatMajetek);
	};

	componentDidMount() {
		// if component mounts (i.e. is rendered for the first time), reload
		// the contents
		this.reload();
	}

	reload = () => {
		// fetches new data from API
		get("/portfolio/majetek" + (this.state.perPage ? '?perPage=' + this.state.perPage : '')).then(response => {
			// updates local state
			// (and causes re-render)
			let firstColumnWidth = 30;
			if (response) {

				let showNames = false;
				let name = null;

				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {
						this._dateHelper.reformatDates(result);
						this._dateHelper.reformatDates(result.formData);

						if (name !== null && result.jmeno !== name) {
							showNames = true;
						}

						name = result.jmeno;

						if (result.druh === "auto" || result.druh === "komod" || result.druh === "pozem") {
							firstColumnWidth = 60;
						}
					});
				}

				this.setState({majetek: response.data.data, firstColumnWidth, showNames});

				dataCache = response.data.data;
				firstColumnWidthCache = firstColumnWidth;
			}
		});
	}

	closeModal = () => {
		if (!this.state.disabledCloseModal) {
			this.toggleModal(false)
		} else {
			alertDialog("Probíhá nahrávání dokumentů", "Během nahrávání dokumentů nelze okno uzavřít. Vyčkejte než se všechny nové dokumenty uloží.");
		}
	};

	toggleModal = (modalIsOpen, activeItem = null) => {

		if (activeItem && activeItem.documents === undefined) {
			activeItem.documents = [];
		}

		if (activeItem && activeItem.id && activeItem.documents.length === 0) {
			this._client.__dokumenty_index(undefined, undefined, activeItem.id).then((result) => {
				if (result) {
					activeItem.documents = result.data.data.db;
					this.setState({modalIsOpen, activeItem});
				}
			});
		} else {
			this.setState({modalIsOpen, activeItem});
		}

		modalBodyScrollPrevent(modalIsOpen, "majetek");
	};

	assetWizardDelete = (name, data) => {
		if (!this.state.disabledDelete) {
			httpDelete("/portfolio/majetek" + (data.id ? "?id=" + data.id : ""), data.data).then(
				response => {
					if (response) {
						PubSub.publish('onMajetekSmazan', {data: data});
						this.reload();
						this.toggleModal(false);
					}
				}
			);
		}
	};

	assetWizardSubmit = data => {
		// to ensure that the modal does not close
		// after submit with files
		this.setState({activeItem: data});

		let dataReformated = JSON.parse(JSON.stringify(data.data));

		let hideSpinner = false;

		if (data.files && data.files.length > 0) {
			hideSpinner = true;
			PubSub.publish('uploadSmlouvyFilesProgress', {
				data: data,
				target: this,
				percentCompleted: 0
			});
		}

		this._dateHelper.reformatDates(dataReformated, true);

		if (data.files && data.files.length > 0) {
			this.setState({disabledCloseModal: true, disabledDelete: true});
		}

		if (data.id) {
			put("/portfolio/majetek" + (data.id ? "?id=" + data.id : ""), dataReformated, hideSpinner).then(
				response => {
					if (response) {

						if (data.files && data.files.length > 0) {
							this._client.uploadPropertyImages(data.id, data.files, true).then((r) => {
								this.reload();
								this.toggleModal(false);
								this.setState({disabledCloseModal: false, disabledDelete: false});
								PubSub.publish('refreshAllDocuments');
							});
						} else {
							this.reload();
							this.toggleModal(false);
						}

						PubSub.publish('onMajetekAktualizovan', {data: data});
					}
				}
			);
		} else {
			post("/portfolio/majetek", dataReformated, null, hideSpinner).then(
				response => {
					if (response) {

						let propertyID = response.data.data.db.id;

						if (data.files && data.files.length > 0) {
							this._client.uploadPropertyImages(propertyID, data.files, true).then((r) => {
								this.reload();
								this.toggleModal(false);
								this.setState({disabledCloseModal: false, disabledDelete: false});
								PubSub.publish('refreshAllDocuments');
							});
						} else {
							this.reload();
							this.toggleModal(false);
						}

						PubSub.publish('onMajetekAktualizovan', {data: data});
					}
				}
			);
		}
	};

	hasItems = () => {
		return this.state.majetek !== undefined && this.state.majetek.db !== undefined && this.state.majetek.db.length !== 0;
	};

	render = () => {
		return (
			<div className="majetek">

				<h1>
					Majetek
					{this.state.readAccessOnly ?
						''
						:
						<a href="#dummy" className="add" onClick={(e) => {
							e.preventDefault();
							this.toggleModal(true);
						}}><i className={"fa fa-plus"}></i></a>
					}
				</h1>

				{this.state.majetek === undefined ?
					<div className="row">
						<div className="col-sm-3">
							<div className={'local-spinner'}></div>
						</div>
					</div>
					:
					!this.hasItems() ? <div className={'empty-list'}>Nemáte žádný vložený majetek</div> : ''
				}

				<div className={'responsive-table__container'}>
					<div
						className="responsive-table responsive-table--sm responsive-table--first-icon responsive-table--second-next-first">
						{this.state.majetek && this.state.majetek.db.map((result) => {

								let description = result.lokalita;
								if (result.druh === "auto") {
									description = result.typ;
								} else if (result.druh === "komod") {
									description = result.typ;
								}

								return (
									<div key={result.formData.id}>
										<div key={result.formData.id} className="tr table-row pointer"
											 onClick={() => this.toggleModal(true, {
												 id: result.formData.id,
												 type: result.formData.druh,
												 data: result.formData
											 })}>
											<div className="td td--width--30">
												<div className={`icon oblm`}>M</div>
											</div>
											<div className={"td text-nowrap td--bigger-space"}>
												<div className={"td--width--" + this.state.firstColumnWidth}>
													{result.formData.nazev.trim().length > 0 ? result.formData.nazev : result.druh_nazev}
												</div>
											</div>
											<div className="td width--100--percent text-ellipsis property-table__address">
												{description}
											</div>
											<div className="td text-nowrap">
												{result.datum_koupeno}
											</div>
											{this.state.showNames === true ?
												<div className="td text-nowrap--big-screen">
													{result.jmeno}
												</div>
												: ""}
										</div>
                    {/*Schovani sekce prodeje majetku*/}
										{/*<ProdejMajetkuPrehled item={{id_majetku: result.formData.id, prodejStavy: result.prodejStavy}}/>*/}
										{/*<PronajemMajetkuPrehled item={{id_majetku: result.formData.id, pronajmy: result.pronajmy}}/>*/}
									</div>
								)
							}
						)}
					</div>
				</div>

				<AssetWizard
					onSubmit={this.assetWizardSubmit.bind(this)}
					onDelete={this.assetWizardDelete.bind(this)}
					closeModal={this.closeModal.bind(this)}
					modalIsOpen={this.state.modalIsOpen}
					asset={this.state.activeItem}
				/>
			</div>
		);
	};
}

export default Majetek;
