// this is the entry point of the whole app
// it basically "injects" react app to html

// IE11 compatibility
import 'react-app-polyfill/ie11';
import 'core-js/fn/array/includes';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister } from './registerServiceWorker';
import Container from "./utils/container"
// import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import moment from "moment/moment";

Moment.globalFilter = (d) => {
	if (d === "Invalid date") {
		return '';
	}
	return d;
};

moment.globalFilter = (d) => {
	if (d === "Invalid date") {
		return '';
	}
	return d;
};

window.container = new Container();
//window.container.inject(React);

ReactDOM.render(<App/>, document.getElementById('root'));
unregister();
