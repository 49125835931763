import React, {Component} from "react";
import Login from "./Login";
import Header from "./../Header.js";
import Loading from "../utils/Loading";
import {getContainer} from "../../utils/generic";
import {logout} from "../../utils/Api";

const SYSTEM_NOT_AVAILABLE_TEXT = "E: system neexistuje";
const PASSWORD_CHANGED_TEXT = "Vaše heslo bylo změněno, můžete se přihlásit.";

let myplannTextOsloveniCache = null;
let myplannTextUvodniCache = null;

class LoginPage extends Component {

	isComponentMounted: boolean = false;

	constructor(props) {
		super(props);

		this.state = {
			myplann_text_osloveni: myplannTextOsloveniCache,
			myplann_text_uvodni: myplannTextUvodniCache,
			logo: sessionStorage.getItem('logo'),
			passwordChanged: props.location && props.location.passwordChanged,
      notExist: false,
		};

		this._client = getContainer().getMyplannClient();

		if (props && props.location && props.location.logout) {
			logout(false);
		}
	}

	componentDidMount = () => {
		this.isComponentMounted = true;

		if (this.state.myplann_text_osloveni === null || this.state.myplann_text_uvodni === null || this.state.logo === null) {

			this._client.getNastaveni(false).then((all) => {
				let myplann_text_osloveni = (all.myplann_text_osloveni === null ? '' : all.myplann_text_osloveni);
				let myplann_text_uvodni = (all.myplann_text_uvodni === null ? '' : all.myplann_text_uvodni);

				if (this.isComponentMounted) {
					this.setState({
						myplann_text_osloveni,
						myplann_text_uvodni,
						logo: (all.logo === null ? '' : all.logo),
					});
				}

				myplannTextOsloveniCache = myplann_text_osloveni;
				myplannTextUvodniCache = myplann_text_uvodni;
			}).catch((e) => {

        console.log('e', e);
				if (this.isComponentMounted) {
					this.setState({
						notExist: true,
					});
				}

				myplannTextOsloveniCache = null;
				myplannTextUvodniCache = null;
			});

		}
	};

	componentWillUnmount = () => {
		this.isComponentMounted = false;
	};

	render = () => {

		return (
			<div className="login-page">
				<Loading />
				{this.state.notExist ? (
				  <div className={'login-load-error'}><h1>{SYSTEM_NOT_AVAILABLE_TEXT}</h1></div>
          ) : (
					<Page
						logo={this.state.logo}
						myplann_text_osloveni={this.state.myplann_text_osloveni}
						myplann_text_uvodni={this.state.myplann_text_uvodni}
						location={this.props.location}
						passwordChanged={this.state.passwordChanged}
					/>
				)}
			</div>
		);
	};
}

const Page = ({ logo, myplann_text_osloveni, myplann_text_uvodni, location, passwordChanged }) => (
	<React.Fragment>
		<Header showBtns={false} showPartnerLogo={true} logoLinkHref={'/login'} logo={logo}/>
		<div className="container">
			<div className="row">
				<div className="col-md-8 col-sm-7 bg-white bg-sm-none">
					<div className="promo promo--with-notebook">
						<div className="text">
							<h1>{myplann_text_osloveni}</h1>
							<p>{myplann_text_uvodni}</p>
						</div>
					</div>
				</div>

				<div className="col-md-4 col-sm-5 login-form__container">
					<Login
						location={location}
						infoMsg={passwordChanged}
						message={passwordChanged ? PASSWORD_CHANGED_TEXT : null}
					/>
				</div>
			</div>
		</div>
	</React.Fragment>
);

export default LoginPage;
