import React, {Component} from "react";
import ReactDropzone from 'react-dropzone'

export default class Dropzone extends Component {
	constructor(props) {
		super(props);
        this.dropzoneRef = React.createRef();
		this.state = {
			maxSize: props.maxSize,
			mimetypes: props.mimetypes,
            openFileWizard: props.openfilewizard === '1' ? true : false
		};
	}

    componentDidMount() {
        if (this.state.openFileWizard) {
            this.openFileWizard();
        }
    }

    openFileWizard() {
        this.dropzoneRef.open();
    }

	UNSAFE_componentWillReceiveProps(props) {
		if (props) {
			if (props.maxFileUploadSize) {
				this.setState({
					maxFileUploadSize: props.maxFileUploadSize
				});
			}

			if (props.mimetypes) {
				this.setState({
					mimetypes: props.mimetypes
				});
			}
		}
	}

	render() {
		return (
			<ReactDropzone
				{...this.props}
				maxSize={this.state.maxSize}
				accept={this.state.mimetypes}
                ref={dropzone => {
                    this.dropzoneRef = dropzone;
                }}
			>
				{this.props.children}
			</ReactDropzone>
		);
	}
}
