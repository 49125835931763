import React, {Component} from "react";
import {put} from "../../utils/Api";

class ProfilForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: props.user ? props.user.name : "",
		};
	}

	componentDidUpdate = (prevProps) => {
		// update of client name
		if (this.props.user && (prevProps.user === null || this.props.user.name !== prevProps.user.name)) {
			this.setState({
				name: this.props.user.name,
			});
		}
	};

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value});
	};

	onSubmit = (e) => {
		e.preventDefault();

		let name = this.state.name;
		if (name.replace(/\s/g,'').length !== 0) {
			put('/user/info', {
				jmeno: this.state.name
			}).then((res) => {
				if (res) {
					this.setState({
						hasError: false,
						message: "Jméno bylo upraveno"
					});

					let user = this.props.user;
					user.name = this.state.name;
					this.props.userUpdate(user);
				}
			});
		} else {
			this.setState({
				hasError: true,
				message: "Zadejte prosím své jméno"
			});
		}
	};

	render = () => {
		return (
			<div className="profil-form">
				<h2 className={"padding--bottom--5"}>Jméno</h2>
				<form onSubmit={this.onSubmit}>
					{this.state.message ?
						<div className={'row'}>
							<div className={'col-12'}>
								<div
									className={"" + (this.state.hasError ? 'error' : 'success')}>{this.state.message}</div>
							</div>
						</div>
						:
						''
					}
					<div className={'row'}>
						<div className={'col-md-7 col-lg-4'}>
							<input onChange={this.onChange} type="text" name={'name'} value={this.state.name}/>
						</div>
					</div>
					<div className={'row'}>
						<div className={'col-md-7 col-lg-4'}>
							<input type="submit" name={"save"} className={'btn'} value={'uložit'}/>
						</div>
					</div>
				</form>
			</div>
		);
	};
}

export default ProfilForm;