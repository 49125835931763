import React, {Component} from "react";
import NakupMajetkuPrehled from "./majetek/NakupMajetkuPrehled";
import NakupAssetWizard from "./forms_nakup_majetku/NakupAssetWizard";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";

let nakupyCache = undefined;

class Nakupy extends Component {

	constructor(props) {
		super(props);
		this._uzivatel = getContainer().getUzivatel();
		this._dateHelper = getContainer().getDateHelper();
		this._myplannClient = getContainer().getMyplannClient();

		let allowNakupMajetku = sessionStorage.getItem('allow_nakup_majetku');
		if (allowNakupMajetku === "true") {
			allowNakupMajetku = true;
		} else if (allowNakupMajetku === "false") {
			allowNakupMajetku = false;
		}

		this.state = {
			readAccessOnly: this._uzivatel.readAccessOnly(),
			nakupy: nakupyCache,
			allowNakupMajetku,
			activeItem: {id: null, type: null, data: null},
			showNames: false,
		};
	}

	/**
	 * @return {MyplannClient}
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	componentDidMount() {
		// if component mounts (i.e. is rendered for the first time), reload
		// the contents
		this.reload();
	}

	reload = () => {
		if (!this.state.allowNakupMajetku) {
			this._myplannClient.getMplanConfiguration().then(
				response => {
					if (response.allowNakupMajetku) {
						this.setState({allowNakupMajetku: response.allowNakupMajetku});
					}
				}
			);
		}

		// fetches new data from API
		this.getMyplannClient().nakupy().then(response => {
			// updates local state
			// (and causes re-render)
			if (response) {

				let showNames = false;
				let name = null;

				if (response.data && response.data.data && response.data.data) {
					response.data.data.forEach((result) => {
						this._dateHelper.reformatDates(result);
						this._dateHelper.reformatDates(result.all);

						if (name !== null && result.jmeno !== name) {
							showNames = true;
						}

						name = result.jmeno;
					});
				}

				nakupyCache = response.data.data;
				this.setState({nakupy: response.data.data, showNames});
			}
		});
	};

	toggleModal = (modalIsOpen, activeItem = {id: null, type: null, data: null}) => {
		this.setState({modalIsOpen, activeItem});
		modalBodyScrollPrevent(modalIsOpen, "nakupy");
	};

	hasItems = () => {
		return this.state.nakupy !== undefined && this.state.nakupy.length !== 0;
	};

	render = () => {
		return (
			<div className="majetek">

				<h1>
					Poptávky po majetku

					{this.state.readAccessOnly || this.state.allowNakupMajetku !== true ?
						''
						:
						<a href={"#dummy"} className={"add"} onClick={(e) => {
							e.preventDefault();
							this.toggleModal(true);
						}}><i className={"fa fa-plus"}></i></a>
					}
				</h1>

				{this.state.nakupy === undefined ?
					<div className={'local-spinner'}></div>
					:
					!this.hasItems() ? <div className={'empty-list'}>Nemáte žádné poptávky po majetku</div> : ''
				}

				<div className={'responsive-table__container'}>
					<div
						className="responsive-table responsive-table--sm responsive-table--first-icon responsive-table--second-next-first">
						{this.state.nakupy && this.state.nakupy.map((result) => {
								return (
									<div key={result.id_nakupu}>
										<div className="tr table-row pointer" onClick={() => this.toggleModal(true, {
											id: result.id_nakupu,
											type: result.druh,
											data: result
										})}>
											<div className="td td--width--30">
												<div className={`icon oblm`}>M</div>
											</div>
											<div className="td text-nowrap td--bigger-space">
												{result.druh_nazev}
											</div>
											<div className="td width--100--percent">
												{result.lokalita}
											</div>
											<div className="td text-nowrap">
												{result.datum_koupeno}
											</div>
											{this.state.showNames === true ?
												<div className="td text-nowrap--big-screen">
													{result.jmeno}
												</div>
												: ""}
										</div>
										<NakupMajetkuPrehled item={{id_nakupu: result.id_nakupu, nakupyPrehled: result.nakupyPrehled}} onZrusit={(id_nakupu) => {
											this.reload();
										}}/>
									</div>
								)
							}
						)}
					</div>
				</div>

				<div className="majetek-modal">
					<NakupAssetWizard
						asset={this.state.activeItem}
						onSubmit={(data) => {
							this.reload();
							this.toggleModal(false, data);
						}}
						onZrusit={(data) => {
							this.reload();
							this.toggleModal(false, data);
						}}
						closeModal={() => this.toggleModal(false)}
						modalIsOpen={this.state.modalIsOpen}
						resetAfterSubmit={false}
					/>
				</div>
			</div>
		);
	};
}

export default Nakupy;
