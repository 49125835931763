import React from "react";
import moment from "moment/moment";
import BaseMajetekForm from "./BaseMajetekForm";
import DatePickerWithListener from "../../utils/DatePickerWithListener";

const emptyData = {
	typ: "",
	hodnota: "",
	datum_koupeno: "",
	druh: "komod",
};

class KomodForm extends BaseMajetekForm {
	constructor(props) {
		super(props);
		this.state = emptyData;
		this.disabled = false;
		if (props.asset) {
			this.disabled = props.asset.disabled ? props.asset.disabled || this._uzivatel.readAccessOnly() : this._uzivatel.readAccessOnly();
			this.state = Object.assign({}, {...props.asset.data});
		}

		this.state.saveBtn = {
			disabled: false,
			text: "uložit"
		};
	}

	emptyData = () => {
		return emptyData;
	};

	render() {
		return (
			<div>
				<form className={'form'} onSubmit={this.submit}>
					<h1>{this.state.nazev && this.state.nazev.trim().length > 0 ? this.state.nazev : 'Komodita'}</h1>
					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<tbody>
						<tr>
							<td>Druh komodity</td>
							<td><input onChange={this.onChange} type="text" name="typ" value={this.state.typ}
									   disabled={this.disabled}/></td>
						</tr>
						<tr>
							<td>Hodnota komodity</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="hodnota"
									   className="nfthousands dontclear"
									   value={this.showFormatedNumber(this.state.hodnota)} autoComplete={"off"}
									   maxLength={11} disabled={this.disabled}/><span> Kč</span></td>
						</tr>
						<tr>
							<td>Datum pořízení</td>
							<td>
								<DatePickerWithListener
									name={'datum_koupeno'}
									selected={this.state.datum_koupeno ? moment(this.state.datum_koupeno, this.getDateHelper().getActiveFormat(), true) : undefined}
									onChange={date => this.onFieldChange('datum_koupeno', date)}
									dateFormat={this.getDateHelper().getActiveFormat()}
									disabled={this.disabled}
                                    onError={this.onDatepickerError}
                                    onErrorRemoved={this.onDatepickerErrorRemoved}
								/>
							</td>
						</tr>
						{this._uzivatel.readAccessOnly() || this.disabled ? '' :
							<tr className={'conrol-row'}>
								<td className={'td-delete'}>
									{this.state.id !== undefined && this.state.disabledDelete !== true ?
										<span onClick={this.smazat} className={'delete pointer'}>smazat majetek</span> : ''}
								</td>
								<td>
									<input type="submit" name={"add"} className="btn" value={this.state.saveBtn.text}
										   disabled={this.state.saveBtn.disabled}/>
								</td>
							</tr>
						}

						</tbody>
					</table>
				</form>
			</div>
		);
	}
}

export default KomodForm;
