import React, {Component} from 'react';
import Loading from "../utils/Loading";
import Header from "../Header";
import {getContainer} from "../../utils/generic";
import {parse_query_string} from "../../utils/urlparse";
import {Redirect} from "react-router";

class VytvoreniPristupu extends Component {
  constructor(props) {
    super(props);
    this._client = getContainer().getMyplannClient();

    this.state = {
      tokenExpired: false,
      tokenUsed: false,
      token: "",
      tokenError: true,
      password: "",
      passwordAgain: "",
      msg: "",
      phone: false,
      error: false,
      step: 1,
      pin: "",
      redirectToLoginPage: false,
    }

    let qs = parse_query_string(this.props.location.search.substring(1));
    this.state.token = qs.token;
    this.state.token = this.state.token ? this.state.token : "";
  }

  componentDidMount() {
    if (this.state.token) {
      this.validateToken();
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  validateToken = () => {
    this._client._sign_vytvoreniPristupuZvalidovatToken(this.state.token).then((response) => {
      if (response && response.data && response.data.data) {
        if (response.data.data.isTokenValid) {
          this.setState({
            tokenError: false,
            tokenExpired: response.data.data.expired,
            tokenUsed: response.data.data.tokenUsed,
            phone: response.data.data.phone,
          })
        }
      }
    })
  }

  setPassword = (e) => {
    e.preventDefault();
    if (this.checkPasswordStrength()) {
      this._client._sign_vytvoreniPristupuNastaveniHesla(this.state.token, this.state.password).then((response) => {
        if (response && response.data && response.data.data) {
          if (response.data.data.passwordSet) {
            this.setState({step:  2});
          } else {
            this.setState({
              error: true,
              msg: "Selhalo odeslání potvrzovacího SMS kódu. Zkuste to za chvíli znovu. Pokud problémy přetrvají, kontaktujte svého poradce."
            });

          }
        } else {
          this.setState({
            error: true,
            msg: "Selhala komunikace se serverem. Zkuste to za chvíli znovu. Pokud problémy přetrvají, kontaktujte svého poradce."
          });
        }
      });
    }
    return false;
  }

  onClickZnovuOdeslatSmsKod = (e) => {
    e.preventDefault();
    this._client._sign_vytvoreniPristupuNastaveniHesla(this.state.token, this.state.password).then((response) => {
      if (response && response.data && response.data.data) {
        if (response.data.data.passwordSet) {
          this.setState({
            error:  true,
            msg: "Byla Vám odeslána nová SMS zpráva s potvrzovacím kódem."
          });
        } else {
          this.setState({
            error: true,
            msg: "Selhalo odeslání potvrzovacího SMS kódu. Zkuste to za chvíli znovu. Pokud problémy přetrvají, kontaktujte svého poradce."
          });

        }
      } else {
        this.setState({
          error: true,
          msg: "Selhala komunikace se serverem. Zkuste to za chvíli znovu. Pokud problémy přetrvají, kontaktujte svého poradce."
        });
      }
    });
  }

  confirmSms = (e) => {
    e.preventDefault();

    if (this.state.pin !== '') {
      this.setState({
        msg: false
      })
      this._client._sign_vytvoreniPristupuPotvrzeni(this.state.token, this.state.pin).then((response) => {
        if (response && response.data && response.data.data) {
          if (response.data.data.success) {
            this.setState({
              redirectToLoginPage: true
            });
          }
        }
        this.setState({
          error: true,
          msg: "Nepodařilo se potvrdit nastavení přístupu.",
        });
      });
    } else {
      this.setState({
        error: true,
        msg: "Není vyplněný SMS kód pro potvrzení přístupu."
      });
      return false;
    }
    return false;
  }

  checkPasswordStrength = () => {
    if (this.state.password === '' || this.state.password.length < 8) {
      this.setState({
        error: true,
        msg: "Heslo je příliš krátké, zadejte heslo, které má alespoň 8 znaků."
      });
      return false;
    }
    if (!this.state.password.match(/[a-z]+/)) {
      this.setState({
        error: true,
        msg: "Heslo musí obsahovat malá písmena."
      });
      return false;
    }
    if (!this.state.password.match(/[A-Z]+/)) {
      this.setState({
        error: true,
        msg: "Heslo musí obsahovat velká písmena."
      });
      return false;
    }
    if (!this.state.password.match(/[0-9]+/)) {
      this.setState({
        error: true,
        msg: "Heslo musí obsahovat číslice."
      });
      return false;
    }
    if (this.state.password !== this.state.passwordAgain) {
      this.setState({
        error: true,
        msg: "Heslo a jeho potvrzení se neshodují."
      });
      return false;
    }
    return true;
  }

  render() {
    if (this.state.redirectToLoginPage) {
      return <Redirect to={{
        pathname: '/login',
        passwordChanged: true
      }}/>
    }

    return (
      <div>
        <Loading show={false}/>
        <Header showBtns={false} showPartnerLogo={true} logoLinkHref={'/login'}/>
        <div className="content">
          <div className="vytvoreni-pristupu-page">
            <div className={'container'}>
              <div className="row row--smaller-spaces align-items-start justify-content-md-center">
                <div className="col-lg-6 col-md-8">
                  <div className="cell">
                    <div className="padding--bottom--25">
                      <h1 className="padding--bottom--20">Vytvoření přístupu</h1>
                      { this.state.tokenError ?
                        <div>
                          <p>Je nám líto, ale Vámi zadaná adresa není platná. Pokud jste použili odkaz, který Vám byl zaslán e-mailem, kontaktujte svého poradce pro zaslání odkazu nového.</p>
                        </div>
                        : this.state.tokenExpired ?
                          <div>
                            <p>Bohužel platnost odkazu pro vytvoření uživatelského přístupu vypršela. Kontaktujte svého poradce pro zaslání odkazu nového.</p>
                          </div>
                          : this.state.tokenUsed ?
                            <div>
                              <p>Bohužel byl již tento odkaz pro vytvoření uživatelského přístupu použit. Kontaktujte svého poradce pro zaslání odkazu nového.</p>
                            </div>
                            : !this.state.phone ?
                              <div>
                                <p>Je nám líto, ale Váš poradce neudal Vaše telefonní číslo. Bez něj není možné Vám uživatelský přístup vytvořit. Kontaktujte svého poradce.</p>
                              </div>
                            : this.state.step === 1 ?
                            <div>
                              <form onSubmit={this.setPassword}>
                                <div className={'row padding--bottom--5'}>
                                  <div className={'col-sm-12'}>
                                    <p>Zadejte heslo dlouhé minimálně 8 znaků složené z malých a velkých písmen a čísel.</p>
                                  </div>
                                </div>
                                <div className={'row padding--bottom--5 padding--top--5'}>
                                  <div className={'col-sm-12 col-md-6'}>
                                    <label htmlFor="">Zadejte nové
                                      heslo:</label>
                                    <input onChange={this.onChange}
                                           type="password" name={'password'}
                                           placeholder={''}
                                           value={this.state.password}/>
                                  </div>
                                  <div className={'col-sm-12 col-md-6'}>
                                    <label htmlFor="">Zadejte nové heslo
                                      znovu:</label>
                                    <input onChange={this.onChange}
                                           type="password"
                                           name={'passwordAgain'}
                                           placeholder={''}
                                           value={this.state.passwordAgain}/>
                                  </div>
                                </div>

                                <div className={'row'}>
                                  <div className={'col-sm-6 col-md-6 col-lg-5'}>
                                    <input type="submit"
                                           className={'btn width--100--percent'}
                                           name={'submit'} value={'Potvrdit'}/>
                                  </div>
                                </div>
                              </form>
                              {this.state.msg ?
                                <div className={'msg-container padding--top--5'}>
                                  <p className={'error'}>
                                    {this.state.msg}
                                  </p>
                                </div>
                                :
                                ''
                              }
                            </div>
                              : this.state.step === 2 ?
                                  <div>
                              <form onSubmit={this.confirmSms}>
                                <div className={'row padding--bottom--5'}>
                                  <div className={'col-sm-12'}>
                                    <p>Na Vaše telefonní číslo byl odeslán SMS kód pro potvrzení vytvoření přístupu. Kód zadejte do formuláře níže.</p>
                                  </div>
                                </div>
                                <div className={'row padding--bottom--5'}>
                                  <div className={'col-sm-6 col-md-6 col-lg-5'}>
                                    <label htmlFor="">Kód ze SMS:</label>
                                    <input onChange={this.onChange} type="text"
                                           name={'pin'}
                                           placeholder={''}
                                           value={this.state.pin}/>
                                  </div>
                                </div>

                                <div className={'row'}>
                                  <div className={'col-sm-6 col-md-6 col-lg-5'}>
                                    <input type="submit"
                                           className={'btn width--100--percent'}
                                           name={'submit'} value={'Potvrdit'}/>
                                  </div>
                                </div>
                                <div className={"top-space--15--important"}>
                                  <span onClick={this.onClickZnovuOdeslatSmsKod}	className={"pointer"}>Zaslat SMS kód znovu</span>
                                </div>
                              </form>
                                  {this.state.msg ?
                                      <div className={'msg-container padding--top--5'}>
                                        <p className={'error'}>
                                          {this.state.msg}
                                        </p>
                                      </div>
                                      :
                                      ''
                                  }
                                  </div>
                              : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VytvoreniPristupu;
