import moment from "moment/moment";

export default class DateHelper {

	getActiveFormat = (activeFormat = null) => {
		if (activeFormat !== null) {
			return activeFormat;
		} else {
			return "D.M.YYYY"; //cs locale
		}
	};

	fixInvalidDate = (d, inputDate) => {
		if (d === "Invalid date") {
			return inputDate;
		}
		return d;
	};

	formatDate = (inputDate, assertTime, activeFormat = null) => {
		let d;
		if (assertTime) {
			d = moment(inputDate, "YYYY-MM-DD HH:mm:ss").format(this.getActiveFormat(activeFormat));
		} else {
			d = moment(inputDate, "YYYY-MM-DD").format(this.getActiveFormat(activeFormat));
		}
		return this.fixInvalidDate(d);
	};

	formatDateRevert = (inputDate, assertTime, activeFormat = null) => {
		let d;
		if (assertTime) {
			d = moment(inputDate, this.getActiveFormat(activeFormat)).format("YYYY-MM-DD HH:mm:ss");
		} else {
			d = moment(inputDate, this.getActiveFormat(activeFormat)).format("YYYY-MM-DD");
		}
		return this.fixInvalidDate(d, inputDate);
	};

	reformatDates = (data, revert = false, assertTime = true, ommitFields = []) => {
		if (data) {
			if (data.datum && ommitFields.indexOf('datum') === -1) {
				data.datum = this.reformatDate(data.datum, revert, assertTime);
			}
			if (data.pocatek && ommitFields.indexOf('pocatek') === -1) {
				data.pocatek = this.reformatDate(data.pocatek, revert, assertTime);
			}
			if (data.konec && ommitFields.indexOf('konec') === -1) {
				data.konec = this.reformatDate(data.konec, revert, assertTime);
			}
			if (data.datum_konce_fixace && ommitFields.indexOf('datum_konce_fixace') === -1) {
				data.datum_konce_fixace = this.reformatDate(data.datum_konce_fixace, revert, assertTime);
			}
			if (data.datum_koupeno && ommitFields.indexOf('datum_koupeno') === -1) {
				data.datum_koupeno = this.reformatDate(data.datum_koupeno, revert, assertTime);
			}
			if (data.datumx && ommitFields.indexOf('datumx') === -1) {
				data.datumx = this.reformatDate(data.datumx, revert, assertTime);
			}
		}
	};

	reformatDate = (string, revert = false, assertTime = true, activeFormat = null) => {
		if (revert) {
			return this.formatDateRevert(string, assertTime, activeFormat);
		}
		return this.formatDate(string, assertTime, activeFormat);
	};

	createMomentInstance = (value, strict : boolean = false, format : string = null) => {
		return moment(value, this.getActiveFormat(format), strict);
	};
}
