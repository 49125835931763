import React, {Component} from "react";

export default class Input extends Component {

	constructor(props) {
		super(props);
		this.state = {
			label: props.label ? props.label : props.name
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({});
	}

	render() {

		if (this.props.type === 'checkbox') {
			return (
				<span>
                    <input {...this.props} value={(this.props.value ? this.props.value : "")}
						   className={"text-checkbox"} defaultChecked={(this.props.value ? this.props.value : false)}
						   id={this.props.name}/>
                    <label htmlFor={this.props.name}>{this.state.label}</label>
                </span>
			);
		}

		return (
			<input {...this.props} value={(this.props.value ? this.props.value : "")}/>
		);
	};
}
