import React from "react";
import * as PubSub from "pubsub-js";
import DokumentyKeSmlouve from "./DokumentyKeSmlouve";
import BaseSmlouvyForm from "./BaseSmlouvyForm";
import moment from "moment/moment";
import SmlouvyDropzone from "../SmlouvyDropzone";
import ForceUpdateScrollbars from "../../ForceUpdateScrollbars/ForceUpdateScrollbars";
import AutocompleteSpolecnost from "./AutocompleteSpolecnost";
import AutocompleteProdukt from "./AutocompleteProdukt";
import DatePickerWithListener from "../../utils/DatePickerWithListener";

// initial form data
const emptyData = {
	typ: 'stspor',
	oblast: 4,
	cislo_smlouvy: '',
	platba: '',
	cetnost_plateb: '',
	cilova_castka: ''
};

class StavebniSporeniForm extends BaseSmlouvyForm {
	constructor(props) {
		super(props);
		this.state = emptyData;

		// if asset is in the props
		// fill the state with it
		if (props.asset) {
			// Object.assign "merges" objects (try it yourself)
			this.state = Object.assign({}, {...props.asset.data});
			this._dokumenty = props.asset.dokumenty;
		}

		this.state.saveBtn = {
			disabled: false,
			text: "uložit"
		};
		this.state.disabledDelete = false;
	}

	UNSAFE_componentWillMount = function () {
		this._droppedFile = PubSub.subscribe('dropzoneDroppedFile', this.droppedFile.bind(this));
		this._deletedFile = PubSub.subscribe('dropzoneDeletedFile', this.deletedFile.bind(this));
		this._deletedSavedFile = PubSub.subscribe('smazatPortfolioDalsiDokument', this.deletedSavedFile.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._droppedFile);
		PubSub.unsubscribe(this._deletedFile);
		PubSub.unsubscribe(this._deletedSavedFile);
	};

	droppedFile = (message, data) => {
		if (data.files && data.files.lenght !== 0) {
			let saveBtn = this.state.saveBtn;
			saveBtn.text = "nahrát soubory a uložit";
			this.setState({saveBtn});
		}
	};

	deletedFile = (message, data) => {
		if (data.files) {
			let existFile = false;
			data.files.forEach((item) => {
				if (item !== undefined || item !== null) {
					existFile = true;
				}
			});

			if (existFile === false) {
				let saveBtn = this.state.saveBtn;
				saveBtn.text = "uložit";
				this.setState({saveBtn});
			}
		}
	};

	deletedSavedFile = (message, data) => {
		if (this._dokumenty) {
			this._dokumenty = this._dokumenty.filter(function (item) {
				return item.id !== data.id;
			});
			this.setState({});
		}
	};

	formSubmit = (e) => {
		let saveBtn = this.state.saveBtn;
		saveBtn.disabled = true;
		this.setState({saveBtn: saveBtn, disabledDelete: true});
		this.submit(e);
	};

	render() {
		return (
			<form className="form" onSubmit={this.formSubmit}>
				<h1>{this.state.nazev && this.state.nazev.trim().length > 0 ? this.state.nazev : 'Stavební spoření'}</h1>
				<table className={'modal-form-table'}>
					<tbody>
					<tr>
						<td>Číslo smlouvy</td>
						<td><input onChange={this.onChange} type="text" name="cislo_smlouvy"
								   value={this.state.cislo_smlouvy}/></td>
						<td></td>
						<td></td>
					</tr>

					<tr>
						<td>Producent</td>
						<td><AutocompleteSpolecnost oblast={this.state.oblast} value={this.state.producent}
													name="producent" onChange={this.onChange}/></td>
						<td>Produkt</td>
						<td><AutocompleteProdukt oblast={this.state.oblast} producent={this.state.producent} selectedProducentSuggestion={this.state.selectedProducentSuggestion}
												 value={this.state.produkt} name="produkt" onChange={this.onChange}/>
						</td>
					</tr>

					<tr>
						<td>Platba</td>
						<td><input onChange={this.onChangeOnlyNumbers} type="text" name="platba" className="nfthousands"
								   value={this.showFormatedNumber(this.state.platba)} maxLength={11}
								   autoComplete={"off"}/> Kč
						</td>
						<td>Četnost</td>
						<td><input onChange={this.onChange} type="text" name="cetnost_plateb" autoComplete={"off"}
								   value={this.state.cetnost_plateb}/></td>
					</tr>
					<tr>
						<td>Cílová částka</td>
						<td><input onChange={this.onChangeOnlyNumbers} type="text" name="cilova_castka"
								   className="nfthousands"
								   value={this.showFormatedNumber(this.state.cilova_castka)} maxLength={11}/> Kč
						</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Datum podpisu</td>
						<td>
							<DatePickerWithListener
								name={'datum'}
								selected={this.state.datum ? moment(this.state.datum, this.getDateHelper().getActiveFormat(), true) : undefined}
								onChange={date => this.onDateChange('datum', date)}
								dateFormat={this.getDateHelper().getActiveFormat()}
                                onError={this.onDatepickerError}
                                onErrorRemoved={this.onDatepickerErrorRemoved}
							/>
						</td>
						<td colSpan={2}></td>
					</tr>
					<tr>
						<td>Datum počátku smlouvy</td>
						<td>
							<DatePickerWithListener
								name={'pocatek'}
								selected={this.state.pocatek ? moment(this.state.pocatek, this.getDateHelper().getActiveFormat(), true) : undefined}
								onChange={date => this.onDateChange('pocatek', date)}
								dateFormat={this.getDateHelper().getActiveFormat()}
                                onError={this.onDatepickerError}
                                onErrorRemoved={this.onDatepickerErrorRemoved}
							/>
						</td>
						<td>Datum ukončení</td>
						<td>
							<DatePickerWithListener
								name={'konec'}
								selected={this.state.konec ? moment(this.state.konec, this.getDateHelper().getActiveFormat(), true) : undefined}
								onChange={date => this.onDateChange('konec', date)}
								dateFormat={this.getDateHelper().getActiveFormat()}
                                onError={this.onDatepickerError}
                                onErrorRemoved={this.onDatepickerErrorRemoved}
							/>
						</td>
					</tr>
					{this._uzivatel.readAccessOnly() ? '' :
						<tr className={'conrol-row'}>
							<td colSpan={'2'} className={'td-delete'}>
								{this.state.id !== undefined && this.state.disabledDelete === false ?
									<span onClick={this.smazat} className={'delete pointer'}>smazat smlouvu</span> : ''}
							</td>
							<td colSpan={'2'} className={'td-submit'}>
								<input type="submit" name={"add"} className={"btn"}
									   value={this.state.saveBtn ? this.state.saveBtn.text : "uložit"}
									   disabled={this.state.saveBtn ? this.state.saveBtn.disabled : false}/>
							</td>
						</tr>
					}
					</tbody>
				</table>

				<div>
					<h2 className={'padding--bottom--10 ' + (this._uzivatel.readAccessOnly() ? 'padding--top--10 ' : '') + 'font-size--14'}>Dokumenty
						ke smlouvě</h2>
					<div className={'contract-documents__container'}>
						<ForceUpdateScrollbars
							className="contract-documents__scrollbox"
							autoHeight
							autoHeightMin={0}
							autoHeightMax={234}
							forceupdaterendertimer={1000}
							identifier={'stavebniSporeni-scrollbar-dokumenty'}
						>
							<div className={'contract-documents__scrollbox__container'}>
								<SmlouvyDropzone onDrop={this.onDrop.bind(this)}/>
								<DokumentyKeSmlouve dokumenty={this.getDokumenty()}/>
							</div>
						</ForceUpdateScrollbars>
					</div>
				</div>

			</form>
		);
	}

	getDokumenty = () => {
		return this._dokumenty;
	};
}

export default StavebniSporeniForm;
