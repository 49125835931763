// this component wraps the process of adding a new
// portfolio item into one block

import React, {Component} from "react";
import propertyAssetsTypes, {getPropertyAssetTypeIDFromName} from "../../../data/propertyAssetsTypes";
import Modal from "../../utils/Modal/Modal";
import {getContainer} from "../../../utils/generic";
import confirmDialog from "../../../utils/confirmDialog";

class AssetWizard extends Component {
	constructor(props) {
		super(props);
		this._ismounted = false;
		this.state = {
			confirmClose: false,
			type: (props.asset ? props.asset.type : null),
			id: (props.asset ? props.asset.id : null),
		};

		this._dateHelper = getContainer().getDateHelper();
	}

	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	// we need this for the same reasons described
	// in Appartment.js
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.asset) {
			this.setState({
				type: nextProps.asset.type,
				id: nextProps.asset.id
			});
		} else {
			// Firstly we need close the modal window and then we can set data to null.
			setTimeout(() => {
				// fixed - Can't call setState (or forceUpdate) on an unmounted component.
				if (this._ismounted) {
					this.setState({id: null, type: null, confirmClose: false,});
				}
			}, 300);
		}
	}

	selectAssetType = type => {
		this.setState({type});
	};

	subformSubmit = (data, files) => {
		let sendingData = data;
		delete sendingData.saveBtn;
		delete sendingData.documents;
		delete sendingData.files;

		let d = {
			type: this.state.type,
			id: this.state.id,
			data,
			files,
		};

		this.setState({
			confirmClose: false,
		});

		this.props.onSubmit(d);
	};

	closeModal = () => {
		if (this.state.confirmClose) {
			confirmDialog("Neuložené změny", "Opravdu si přejete zavřít okno bez uložení změn?", "zavřít okno").then(
				result => {
					this.setState({
						confirmClose: false,
					});

					this.props.closeModal();
				},
				result => {
				}
			);
		} else {
			this.props.closeModal();
		}
	};

	onFormChange = () => {
		if (!this.state.confirmClose) {
			this.setState({
				confirmClose: true,
			})
		}
	};

	renderList = () => {
		return (
			<div>
				<h1>Přidat</h1>
				<ul className="links-center">
					{propertyAssetsTypes.map((data, index) => (
						<li key={index}>
							<span className="links-center-a" onClick={() => this.selectAssetType(data.id)}>{data.name}</span>
						</li>
					))}
				</ul>
			</div>
		);
	};

	render() {
		let type = getPropertyAssetTypeIDFromName(this.state.type);
		const Form = type !== null && propertyAssetsTypes[type].form;

		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={this.closeModal}
			>
				{Form ? <Form onSubmit={this.subformSubmit.bind(this)} onFormChange={this.onFormChange.bind(this)}
							  asset={this.props.asset}/> : this.renderList()}
			</Modal>
		);

	}
}

export default AssetWizard;
