import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import DokumentyForm from "./forms/DokumentyForm";
import confirmDialog from "../../utils/confirmDialog";
import fileRenamePromtDialog from "../../utils/fileRenamePromt";
import alertDialog from "../../utils/alertDialog";
import Modal from "../utils/Modal/Modal";
import {getFileIcon} from "../utils/fileIcon";

let documentsCache = undefined;

class Documents extends Component {

	constructor(props) {
		super(props);
		this.state = {
			documents: documentsCache,
			disabledCloseModal: false,
			disabledSubmitBtn: true,
			confirmClose: false,
            openFileWizard: props.openFileWizard,
            modalIsOpen: props.modalIsOpen,
			showNames: false,
		};
		this._client = getContainer().getMyplannClient();
		this._uzivatel = getContainer().getUzivatel();
		this._dateHelper = getContainer().getDateHelper();

		if (props.modalIsOpen) {
			modalBodyScrollPrevent(props.modalIsOpen, 'documents');
		}
	}

    componentDidUpdate = (prevProps) => {
		if (this.props.openFileWizard !== prevProps.openFileWizard || this.props.modalIsOpen !== prevProps.modalIsOpen) {

			this.setState({
				openFileWizard: this.props.openFileWizard,
				modalIsOpen: this.props.modalIsOpen
			});

			modalBodyScrollPrevent(this.props.modalIsOpen, 'documents');
		}
	};

	componentDidMount() {
		this.reload();
	}

	UNSAFE_componentWillMount = function () {
		this._deletedSavedFile = PubSub.subscribe('smazatPortfolioDalsiDokument', this.deletedSavedFile.bind(this));
		this._renamedSavedFile = PubSub.subscribe('renamePortfolioDalsiDokument', this.renamedSavedFile.bind(this));
		this._refreshAllDocuments = PubSub.subscribe('refreshAllDocuments', this.refreshAllDocuments.bind(this));
		this._toggleDocumentsModal = PubSub.subscribe('toggleDocumentsModal', this.toggleDocumentsModal.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._deletedSavedFile);
		PubSub.unsubscribe(this._renamedSavedFile);
		PubSub.unsubscribe(this._refreshAllDocuments);
		PubSub.unsubscribe(this._toggleDocumentsModal);
	};

	deletedSavedFile = (message, data) => {
		if (this.state.documents) {
			let documents = this.state.documents;
			documents.db.forEach((item, index) => {
				if (item.id === data.id) {
					documents.db.splice(index, 1);
				}
			});
			this.setState({documents});
			documentsCache = documents;

			if (documents.db.length === 0) {
				this.props.setDocumentsShowButton("Nahrát nové dokumenty");
			}
		}
	};

	renamedSavedFile = (message, data) => {
		if (this.state.documents) {
			let documents = this.state.documents;
			documents.db.forEach((item, index) => {
				if (item.id === data.id) {
					documents.db[index].nazev = data.name;
				}
			});
			this.setState({documents});
			documentsCache = documents;
		}
	};

	refreshAllDocuments = (message, data) => {
		this.reload();
	};

	toggleDocumentsModal = (message, data) => {
		this.toggleModal(data.modalIsOpen);
	};

	reload = () => {
		getContainer().getMyplannClient().__dokumenty_index(undefined, undefined, undefined).then(response => {
			if (response) {

				let showNames = false;
				let name = null;

				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {

						this._dateHelper.reformatDates(result);

						let mime = null;

						if (result.dokument && result.dokument.length !== 0 && result.dokument.mime !== null) {
							mime = result.dokument.mime;
						} else if (result.sab_dokument !== null && result.sab_dokument.mime !== null) {
							mime = result.sab_dokument.mime;
						}

						if (name !== null && result.jmeno !== name) {
							showNames = true;
						}

						name = result.jmeno;

						if (mime) {
							result.icon = getFileIcon(mime);
						}
					});
				}

				this.setState({documents: response.data.data, showNames});
				documentsCache = response.data.data;

				if (response.data.data.db.length > 0) {
					this.props.setDocumentsShowButton("Zobrazit více");
				} else {
					this.props.setDocumentsShowButton("Nahrát nové dokumenty");
				}

			}
		});
	};


	hasItems = () => {
		return this.state.documents !== undefined && this.state.documents.db !== undefined && this.state.documents.db.length !== 0;
	};

	closeModal = () => {
		if (this.state.confirmClose) {
			confirmDialog("Neuložené změny", "Opravdu si přejete zavřít okno bez uložení změn?", "zavřít okno").then(
				result => {
					this.toggleModal(false);
				},
				result => {
				}
			);
		} else {
			this.toggleModal(false);
		}
	};

	toggleModal = (modalIsOpen) => {
		if (!this.state.disabledCloseModal) {
			this.setState({modalIsOpen});

			if (modalIsOpen === false) {
				this.setState({
					confirmClose: false,
                    openFileWizard: false,
				}, () => {
					this.props.dokumentyModalClosed()
				});
			}

			modalBodyScrollPrevent(modalIsOpen, "documents");
		} else {
			alertDialog("Probíhá nahrávání dokumentů", "Během nahrávání dokumentů nelze okno uzavřít. Vyčkejte než se všechny nové dokumenty uloží.");
		}
	};

	formSubmit = (data, files) => {
		let hideSpinner = true;
		if (files && files.length > 0) {
			this.setState({disabledCloseModal: true, disabledSubmitBtn: true});
			this._client.uploadDokumentyFiles(files, hideSpinner).then((r) => {
				this.setState({disabledCloseModal: false, disabledSubmitBtn: true, confirmClose: false,});
				this.reload();
				PubSub.publish('clearBaseDokumentyForm');
			});
		}
	};

	dropzoneListChanged = (files) => {
		if (files.length > 0 && this.state.disabledSubmitBtn) {
			this.setState({
				disabledSubmitBtn: false,
				confirmClose: true,
			});
		} else if (files.length === 0 && !this.state.disabledSubmitBtn) {
			this.setState({
				disabledSubmitBtn: true,
				confirmClose: false,
			});
		}
	};

	smazatDokument = (id) => {
		confirmDialog("Smazání dokumentu", "Opravdu chcete smazat dokument?", "smazat dokument").then(
			result => {
				PubSub.publish('smazatPortfolioDalsiDokument', {id: id});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	editovatDokument = (documentID) => {
		let document = null;

		if (this.hasItems()) {
			this.state.documents.db.forEach((item) => {
				if (item.id === documentID) {
					document = item;
				}
			});

			if (document !== null) {
				fileRenamePromtDialog(document.nazev).then(
					result => {
						if (result.length > 0) {
							this._client.editovatDokumentKeSmlouve(document.id, {
								nazev: result.substr(0, 50)
							}).then((r) => {
								if (r) {
									document.nazev = result;
									this.setState({});
									PubSub.publish('renamePortfolioDalsiDokument', {id: document.id, name: result});
								}
							});
						}
					},
					result => {
						// `cancel` callback
					}
				);
			} else {
				alertDialog("Soubor nenalezen", "Omlouváme se, ale tento soubor již neexistuje. V případě dotazů se obraťte na svého poradce.");
			}
		}
	};

	renderDocumentsList = (items, showAddedDate = false, showName = false, showControllBtns = false) => {
		return (
			<div>
				{this.state.documents === undefined ?
					<div className={'local-spinner'}></div>
					:
					!this.hasItems() ?
						<div className={'empty-list'}>Nemáte žádné dostupné dokumenty k náhledu</div> : ''
				}

				{this.state.documents && this.state.documents.db && this.state.documents.db.slice(0, items).map((result) => {
						return (
							<div key={result.id}
								 className={"document-row document-row--bigger-icon document-row--bigger-icon " + (showAddedDate || showName ? "document-row--wrap-items" : "")}>
								<div className={'width--25 pointer'} onClick={(e) => {
									this._client.__dokumenty_download(result.id);
								}}>
									{result.icon ?
										<img src={"/img/file-icons/" + result.icon + ".png"} alt={result.icon}/> :
										<div className='icon icon--circle oblm'>D</div>}
								</div>
								<div className={'break-words pointer'} onClick={(e) => {
									this._client.__dokumenty_download(result.id);
								}}>
									<a className={'link-black'}
									   href={this._client.__dokumenty_download_url(result.id)}
									   onClick={(e) => {
										   e.preventDefault();
									   }}>
										{result.nazev}
									</a>
								</div>
								{showAddedDate ?
									<div className={'pointer document-row--date text-nowrap'} onClick={(e) => {
										this._client.__dokumenty_download(result.id);
									}}>
										{result.datum}
									</div>
									: ""
								}
								{showName && this.state.showNames ?
									<div className={'pointer document-row--name text-nowrap'} onClick={(e) => {
										this._client.__dokumenty_download(result.id);
									}}>
										{result.jmeno}
									</div>
									: ""
								}
								<div className="width--30 text-right document-row--controls">
									{!showControllBtns || this._uzivatel.readAccessOnly() ? "" :
										<div className="td width--30 text-right">
											{result.sab_dokument === null ?
												<div>
													<span className="pointer edit d-inline-block hide-text"
													   onClick={() => this.editovatDokument(result.id)}>Upravit soubor</span>
													<span className="delete pointer delete__cross--small d-inline-block hide-text"
													   onClick={() => this.smazatDokument(result.id)}>Smazat soubor</span>
												</div>
												: ""
											}
										</div>
									}
								</div>
							</div>
						);
					}
				)}
			</div>
		)
	};

	render = () => {
        return (
			<div className="documents height--100--percent">

				{this.renderDocumentsList(5, false, false, false)}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
				>
					<h1>Vaše dokumenty</h1>

					{this.renderDocumentsList(1000, true, true, true)}

					{!this._uzivatel.readAccessOnly() ?
						<div className={"top-space--15"}>
							<DokumentyForm onSubmit={this.formSubmit}
										   dropzoneListChanged={this.dropzoneListChanged.bind(this)}
										   asset={this.props.asset}
										   disabledSubmitBtn={this.state.disabledSubmitBtn}
                                           openFileWizard={this.state.openFileWizard}
							/>
						</div>
						:
						''
					}
				</Modal>
			</div>
		);
	};
}

export default Documents;
