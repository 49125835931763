// import React from 'react';
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {loggedIn} from "../utils/Api";
import * as PubSub from "pubsub-js";
import Redirect from "react-router-dom/es/Redirect";

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			redirectToHome: false,
			showBtns: props.showBtns,
			redirect: null,
			showPartnerLogo: props.showPartnerLogo !== undefined ? props.showPartnerLogo : false,
			name: props.user ? props.user.name : "",
			logoLinkHref: props.logoLinkHref ? props.logoLinkHref : '/',
			logout: false,
			logoStyle: this.getLogoStyle(props.logo)
		};
	};

	componentDidUpdate = (prevProps, prevState) => {
		// update of client name
		if (this.props.user && this.props.user.name !== prevState.name) {
			this.setState({
				name: this.props.user.name,
			});
		}

		if (this.props.logo !== undefined && this.props.logo !== prevProps.logo) {
			this.setState({
				logoStyle: this.getLogoStyle(this.props.logo)
			});
		}
	};

	logout = () => {
		this.setState({
			logout: true,
		})
	};

	goHome = () => {
		if (window.location.pathname !== '/') {
			return true;
		} else {
			PubSub.publish('reloadHome', 'yes');
		}
	};

	getLogoStyle = (logo = undefined) => {
        let ret = {};
        if (!logo) {
            logo = sessionStorage.getItem('logo');
        }

        if (logo !== null) {
			if (logo !== '') {
				ret.background = "url(" + logo + ") left center no-repeat";
			} else {
				ret.background = "url('/img/myplann.png') left center no-repeat";
				ret.marginTop = "20px";
			}
		}

		return ret;
	};

	render = () => {

		if (this.state.logout) {
			return <Redirect to={{
				pathname: '/login',
				logout: true
			}} />
		}

		return (
			<header className="header">
				<div className="container">
					<div className="row">
						<div className="col-5">
							<Link to={this.state.logoLinkHref} className="logo" style={this.state.logoStyle} onClick={this.goHome}/>
						</div>
						<div className="col-7">
							{(this.state.showBtns || this.state.showBtns === undefined) && loggedIn() &&
							<div className="header__login-section">
								<Link className="pointer" to='/profil'>{this.state.name}</Link>
								<span className="logout pointer" onClick={this.logout}>Odhlásit</span>
							</div>
							}
							{this.state.showPartnerLogo ?
								<div className={'text-right'}>
									<a href={'https://sabservis.cz/'} target={"_blank"} className={'header__partner-logo hide-text'}>
										Partner SAB servis
									</a>
								</div> : ''}
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
