export function log(data, context) {
	if (isDebugEnabled()) {
		console.log(data);
	}
}

export function isDebugEnabled() {
	return process.env.REACT_APP_DEBUG === '1';
}

/**
 * @returns {Container}
 */
export function getContainer() {
	return window.container;
}

export function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];

		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}

		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}
	return null;
}

export function eraseCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999;';
}

/**
 * If modal is open, then this function hide scrollbar and add right padding to body
 *
 * Modify of DOM is the best way how to do this.
 *
 * https://stackoverflow.com/questions/39962757/prevent-scrolling-using-css-on-react-rendered-components
 * http://reactcommunity.org/react-modal/styles/classes.html
 *
 * @param open bool
 * @param modalIdentifier string Identifier of modal window that call this function
 */
export function modalBodyScrollPrevent(open, modalIdentifier) {
	let body = document.body;
	if (open) {
		if (!body.classList.contains("modal-open")) {
			let scrollDiv = document.createElement("div");
			scrollDiv.className = "scrollbar-measure";
			body.appendChild(scrollDiv);
			let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
			body.removeChild(scrollDiv);

			if (window.innerWidth > document.documentElement.clientWidth) {
				body.style.paddingRight = scrollbarWidth + "px";
			}

			body.classList.add('modal-open');
			body.setAttribute("data-openby", modalIdentifier);
		}
	} else {
		if (body.getAttribute("data-openby") === modalIdentifier) {
			body.classList.remove('modal-open');
			body.style.paddingRight = "0px";
			body.setAttribute("data-openby", "");
		}
	}
}

/**
 *  Removes only first item found
 * @param arr
 * @param item
 * @return {string[]}
 */
export function removeItemFromArrayOfStrings(arr : string[], item : string) {
    let arrClone = arr.slice(0);
    let idx = arrClone.indexOf(item);
    if (idx >= 0) {
        arrClone.splice(idx, 1);
    }
    return arrClone;
}

/**
 * Adds string item into array of strings. If already exists, than the input array is unchanged
 * @param arr
 * @param item
 * @return {string[]}
 */
export function addItemToArrayOfStrings(arr : string[], item : string) {
    let arrClone = arr.slice(0);
    let idx = arrClone.indexOf(item);
    if (idx === -1) { //only once
        arrClone.push(item);
    }
    return arrClone;
}