import {Component} from "react";
import * as PubSub from "pubsub-js";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";

export default class BaseMajetekForm extends Component {
	constructor(props) {
		super(props);
		this._dateHelper = getContainer().getDateHelper();
		this._uzivatel = getContainer().getUzivatel(); //props.di("uzivatel");
		this.readOnly = false;
        this._datepickerErrs = {};
	}

	componentDidMount() {
	}

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	submit = e => {
		e.preventDefault();
		if (this.suggestionMenuOpened === undefined || !this.suggestionMenuOpened) {

			let saveBtn = this.state.saveBtn;
			saveBtn.disabled = true;
			this.setState({saveBtn: saveBtn, disabledDelete: true});

			let data = this.state;
			this.props.onSubmit(data, this.state.files);
		}
	};

	smazat = () => {
		let confirmOptions = {
			additionalConfirmMessage: 'Položka bude nenávratně smazána.<br/>Chcete-li položku zachovat, zavřete křížkem nebo vyberte <em>zrušit</em>.'
		};
		confirmDialog("Smazání majetku", "Opravdu chcete smazat majetek?", "smazat majetek", confirmOptions).then(
			result => {
				// `proceed` callback
				PubSub.publish("smazatMajetek", {id: this.state.id});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	onChange = e => {
		if (!this.readOnly) {
			let name = e.target.name;
			if (name) {
				name = name.replace("-", "_");
				this.setState({[name]: e.target.value});
			}
			this.props.onFormChange();
		}
	};

	onSuggestionMenuOpened = e => {
		this.suggestionMenuOpened = true;
	};

	onSuggestionMenuClosed = e => {
		let _self = this;
		//onSuggestionMenuClosed se zavolá dřív než submit při výběru Enterem, proto to takto zpožďuji
		// pokud by se submit nestačil dokončit za 200ms tak se to bude projevovat tak že se formulář při výběru možnosti Enterem submitne.
		setTimeout(() => {
			_self.suggestionMenuOpened = false;
		}, 200);
	};

	onChangeCheckbox = e => {
		this.setState({[e.target.name]: (e.target.checked ? "1" : "0")});
		this.props.onFormChange();
	};

	onChangeOnlyNumbers = e => {
		const re = /^[0-9\b\s]+$/;
		let value = e.target.value.replace(/\s+/g, "");
		if (value === '' || re.test(value)) {
			this.setState({[e.target.name]: value});
		}

		this.props.onFormChange();
	};

	showFormatedNumber = (number) => {
		if (number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		} else {
			return "";
		}
	};

	onFieldChange = (name, value) => {
		if (!this.readOnly) {
			if (value && value.format) {
				value = value.format(this._dateHelper.getActiveFormat()); // fix problemu kdy se posouvalo datum o den zpatky
			}
			this.setState({[name]: value});
			this.props.onFormChange();
		}
	};

    onDatepickerError = (name, value) => {
        this._datepickerErrs[name] = true;
        this.setState({
            [name]: value, //date field refresh with invalid value.
            // This solves the problem with value overriding to last valid date in datepicker.
            // User is required to fix date input value to save form. He must see the invalid date to correct his mistake.
            saveBtn: {
                disabled: true,
                text: this.state.saveBtn.text
            }
        });
    };

    onDatepickerErrorRemoved = (name, value) => {
        let disabled = false;
        this._datepickerErrs[name] = false;
        if (this._datepickerErrs) {
            for (let key in this._datepickerErrs) {
                if (this._datepickerErrs.hasOwnProperty(key)) {
                    if (this._datepickerErrs[key] === true) {
                        disabled = true;
                    }
                }
            }
        }

        this.setState({
            [name]: value,
            saveBtn: {
                disabled: disabled,
                text: this.state.saveBtn.text
            }
        });
    };
}
