import React from 'react';
import PropTypes from 'prop-types';
import {confirmable, createConfirmation} from 'react-confirm';
import Modal from "../components/utils/Modal/Modal";
import {modalBodyScrollPrevent} from "./generic";

const AlertDialog = ({show, proceed, dismiss, cancel, alertTitle, alertText, options}) => {

	modalBodyScrollPrevent(show, "alertDialog");

	return (
		<Modal
			onRequestClose={dismiss}
			isOpen={show}
		>
			<div className={'modal__alert'}>
				<h1>{alertTitle}</h1>
				<p>{alertText}</p>
				<span className={'btn modal__alert__btn'} onClick={() => cancel()}>Zavřít</span>
			</div>
		</Modal>
	);
};

AlertDialog.propTypes = {
	show: PropTypes.bool,
	proceed: PropTypes.func,
	cancel: PropTypes.func,
	dismiss: PropTypes.func,
	alertTitle: PropTypes.string,
	alertText: PropTypes.string,
	options: PropTypes.object
};

const alertDialog = createConfirmation(confirmable(AlertDialog));

export default function (alertTitle, alertText, options = {}) {
	return alertDialog({alertTitle, alertText, options});
}
