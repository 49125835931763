import React, {Component} from "react";
import {post} from "../../utils/Api";

class RecommendationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
      obsah: '',
      jmeno: '',
      telefon: '',
      email: ''
		};
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value});
		this.props.onChange();
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (this.state.obsah.match(/^(\s)*$/gi)) {
			this.setState({
				hasError: true,
				message: "Zadejte prosím informace o Vašem známém."
			});
		} else if (this.state.jmeno.match(/^(\s)*$/gi)) {
			this.setState({
				hasError: true,
				message: "Zadejte prosím jméno Vašeho známého."
			});
		}  else if (this.state.telefon.match(/^(\s)*$/gi)) {
			this.setState({
				hasError: true,
				message: "Zadejte prosím telefon na Vašeho známého."
			});
		}  else if (this.state.email.match(/^(\s)*$/gi)) {
			this.setState({
				hasError: true,
				message: "Zadejte prosím e-mail na Vašeho známého."
			});
		} else {
			post('/recommendation/submitRecommendation', {
				obsah: this.state.obsah,
				jmeno: this.state.jmeno,
				telefon: this.state.telefon,
				email: this.state.email,
			}).then((res) => {
				if (res) {
					this.setState({
						hasError: false,
						message: "Kontakt byl odeslán.",
						obsah: "",
            jmeno: "",
            telefon: "",
            email: "",
					});

					this.props.onSubmit();
				}
			});
		}
	};

	render = () => {
		return (
			<div>
				<div className="recommendation-form">

					<h2>Můžu být užitečný i Vašim přátelům, kolegům či někomu z rodiny?</h2>
					<h2>Uvítám, pokud mi předáte kontakt na svého známého,<br /> který již o mně ví a čeká, že mu zavolám.</h2>

					<form onSubmit={this.onSubmit}>
						{this.state.message ?
							<div
								className={"padding--bottom--10 " + (this.state.hasError ? 'error' : 'success')}>{this.state.message}
							</div>
							:
							''
						}

						<div className={'input-group'}>
							<input
                onChange={this.onChange}
                name={'jmeno'}
                className={"width--50--percent"}
                value={this.state.jmeno}
                placeholder={'Jméno a příjmení'}
              />
            </div>
            <div className={'input-group'}>
							<input
                onChange={this.onChange}
                name={'telefon'}
                className={"width--50--percent"}
                value={this.state.telefon}
                placeholder={'Telefonní číslo'}
              />
							<input
                onChange={this.onChange}
                name={'email'}
                className={"width--50--percent"}
                value={this.state.email}
                placeholder={'E-mail'}
              />
            </div>
            <div className={'input-group'}>
							<textarea
                onChange={this.onChange}
                name={'obsah'}
                className={"width--100--percent"}
                value={this.state.obsah}
                placeholder={'Řekněte mi něco o svém známém. Jak mu můžu být prospěšný?'}
              />
						</div>
						<div className={'input-group'}>
              <input type="submit" name={"save"} className={'btn submit'} value={'seznámit'}/>
						</div>
					</form>
				</div>
			</div>
		)
	};
}

export default RecommendationForm;
