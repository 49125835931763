import React, {Component} from "react";
import ForceUpdateScrollbars from './../ForceUpdateScrollbars/ForceUpdateScrollbars';
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import Modal from "../utils/Modal/Modal";

class HomeLeadovac extends Component {

	constructor(props) {
		super(props);
    this._client = getContainer().getMyplannClient();
		this.state = {
		  initialized: false,
		  isModalOpen: false,

      iframeUrl: null,
      iframeHeight: null,
      items: []
		};
	}

	componentDidMount() {
    let self = this;
    this._client.getLeadovace().then(response => {
      if (response) {
        if(response.status === 'ok') {
          self.setState({initialized: true, items: response.items});
        } else {
          alert("Došlo k chybě při načítání.");
        }

      } else {
        alert("Došlo k chybě při načítání.");
      }
    });

    window.addEventListener("message", (event) => {
      if(event.data && event.data.height) {
        let iframe = document.getElementById('displayframe');
        if (iframe) {
          iframe.style.height = event.data.height+'px';
        }

      }
    }, false);

  };

	openModal = (item) => {
	  let self = this;

    this.setState({isModalOpen: true}, function() {
      this._client.getLeadovacUrl(item.type).then(response => {
        if (response) {
          if(response.status === 'ok') {
            self.setState({iframeHeight: item.height, iframeUrl: response.url});
          } else {
            alert("Došlo k chybě při načítání.");
          }
        } else {
          alert("Došlo k chybě při načítání.");
        }
      });
    });
    modalBodyScrollPrevent(true, 'leadovac');

  }

  closeModal = () => {
	  this.setState({isModalOpen: false, iframeUrl: null, iframeHeight: null});
    modalBodyScrollPrevent(false, 'leadovac');
  }

	render = () => {

	  let modalStyle = {
	    width: '100%'
    };
	  if(this.state.iframeUrl && typeof ResizeObserver === 'undefined') {
	    modalStyle.height = this.state.iframeHeight;
    }

	  return (
	    <>
	    <Modal
      className={'ModalLeadovac'}
      onRequestClose={()=>this.closeModal()}
      isOpen={this.state.isModalOpen}
    >
      {this.state.iframeUrl && <iframe id={'displayframe'} frameBorder={0} src={this.state.iframeUrl} style={modalStyle} />}
      {!this.state.iframeUrl && <><div className={'local-spinner'}/>Načítám leadovač</>}
    </Modal>
        <div className={'cell cell--half-height leadovac-wrapper'}>
          {/*<h1 className={'cell-title-max-height'}>Leadovače</h1>*/}
          <ForceUpdateScrollbars
            className="cell--half-height__scrollbox scrollbox--md--disabled"
            autoHeight
            autoHeightMin={0}
            autoHeightMax={400}
            forceupdaterendertimer={1000}
          >
            {!this.state.initialized && <><div className={'local-spinner'}/></>}

            <div className={'responsive-table__container'}>
              <div className="responsive-table responsive-table--first-icon" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>

                {this.state.items.map((item)=> {

                  return (
                      <div style={{display: 'block'}} className="tr table-row pointer" onClick={() => {
                        if (item.type !== 'pojisteni-cestovko') {
                          this.openModal(item)
                        }
                      }}>
                        {item.type === 'pojisteni-cestovko' &&
                        <a href={process.env.REACT_APP_BASE_URL + '/leadovac/cestovni-pojisteni-redirect?auth=' + process.env.REACT_APP_AUTH_TOKEN}
                           target="_blank"
                           style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}/>}
                        <div className="td td--width--30">
                          <div>
                            <img src={'/img/leadovac/' + item.icon}/>
                          </div>
                        </div>
                        <div className="td td--bigger-space">
                        <span
                          className={'break-words'}>{item.title}</span>
                        </div>
                      </div>
                    );

                })}

              </div>
            </div>
          </ForceUpdateScrollbars>
        </div>
      </>
    );
	};
}

export default HomeLeadovac;
