import React, {Component} from "react";
import {Link} from "react-router-dom";

class NotFound extends Component {
	render() {
		return (
			<div className={"container"}>
				<div className={"row"}>
					<div className={"col-12 text-center not-found"}>
						<h1>Stránka nenalezena</h1>
						<p>
							Omlouváme se, ale stránka kterou hledáte neexistuje.<br/>
							Můžete pokračovat na <Link to={"/"}>hlavní stránku</Link>.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;
